import { Connection, GetInvitations200ResponseOneOfInner, GetMembers200ResponseOneOf, GetMembers200ResponseOneOfInner, GetOrganizationMemberRoles200ResponseOneOfInner, GetOrganizations200ResponseOneOfInner, GetUsers200ResponseOneOf, GetUsers200ResponseOneOfInner } from "auth0";
import { ISearchRequest } from "../entities/search-request.interface";

export interface Auth0User extends Omit<GetUsers200ResponseOneOfInner, 'app_metadata'> {
  app_metadata: Auth0AppMetadata;
  clickerGroup?: string;
}

export interface Auth0UsersPaged extends Omit<GetUsers200ResponseOneOf, 'users'> {
  users: Auth0User[];
}

export interface Auth0OrganizationInvitation extends Omit<GetInvitations200ResponseOneOfInner, 'app_metadata'> {
  app_metadata: Auth0AppMetadata;
}

export type Auth0Organization = GetOrganizations200ResponseOneOfInner;
export type Auth0Member = GetMembers200ResponseOneOfInner;
export type Auth0MembersPaged = GetMembers200ResponseOneOf;
export type Auth0MemberRole = GetOrganizationMemberRoles200ResponseOneOfInner;
export type Auth0Connection = Connection;

export interface Auth0UserDetails {
  user_id: string;
  email: string;
  email_verified: boolean;
  name: string;
  nickname: string;
  given_name: string;
  family_name: string;
  blocked: boolean;
  user_metadata: any,
  app_metadata: Auth0AppMetadata;
  clickerGroupId: string;
}

export interface Auth0AuthorizerContext {
  email: string;
  user_id: string;
  org_id: string;
  role: Auth0Role;
  lambdaArn?: string;
}

export interface Auth0UserMembership {
  organization: Auth0OrganizationName;
  roles: Auth0Role[];
  clickerGroupId?: string;
}

export interface Auth0CreateUsersRequest {
  users: Auth0CreateUserRequest[];
}

export interface Auth0CreateUserRequest {
  email: string;
  firstName: string;
  lastName: string;
  memberships: Auth0UserMembership[];
  clickerGroupId?: string;
  clientIds: string[];
}

export interface Auth0UpdateUserRequest {
  user_id: string;
  email?: string;
  firstName?: string;
  lastName?: string;
  memberships?: Auth0UserMembership[];
  clickerGroupId?: string;
  clientIds: string[];
}

export interface Auth0RemoveMembershipRequest {
  user_id: string;
  organization: Auth0OrganizationName;
}

export interface Auth0SendUserWelcomeEmailRequest {
  user_id: string;
}

export interface Auth0ResetMfaRequest {
  user_id: string;
}

export interface Auth0GetUserDetailsRequest {
  user_id: string;
}

export interface Auth0GetUsersRequest extends Omit<ISearchRequest, 'entity'> {
}

export interface Auth0GetUsersByRolesRequest {
  roles: Auth0Role[];
}

export interface Auth0AddUserMembershipsResponse {
  membership: Auth0AddedMembership;
  app_metadata: Auth0AppMetadata;
}

export interface Auth0PostRegistrationRequest {
  clickerGroupId: string;
  userId: string;
  organization: Auth0OrganizationName;
}

export interface Auth0AddedMembership {
  connection: Connection;
  organization: Auth0Organization;
  roles: Auth0MemberRole[];
}

export interface Auth0Client {
  id: string;
  name?: string;
}

export interface Auth0AppMetadata {
  api?: boolean;
  gsd?: boolean;
  memberships: Auth0UserMembership[];
  firstName?: string;
  lastName?: string;
  clients?: Auth0Client[];
  requestedBy?: string;
}

export interface Auth0TokenPayload {
  id: string;
  user_id: string;
  email: string;
  email_verified: boolean;
  user_metadata: any;
  app_metadata: Auth0AppMetadata;
  user_client_id: string;
  auth_client_id: string;
  organization_name: string;
  auth_roles: Auth0Role[];
  iss: string;
  sub: string;
  aud: string[];
  scope: string;
  org_id: string;
}

export interface ApiTokenPayload {
  orgId: Auth0OrganizationID;
  username: string;
  password: string;
}

export interface CreateOrgInvitationRequest {
  firstName: string;
  lastName: string;
  organization: Auth0Organization;
  roles: Auth0Role[];
  email: string;
  clickerGroupId?: string;
  invitation?: Auth0OrganizationInvitation;
  clientIds: string[];
  requestedBy: string;
}

export interface Auth0MembershipWithMeta extends Auth0UserMembership {
  version?: string;
}

// These reflect real values in the Auth0 Dashboard
export enum Auth0Role {
  // Legacy roles, TODO: remove
  STAFF = 'STAFF',
  ADMIN = 'ADMIN',
  SUPER_ADMIN = 'SUPER_ADMIN',

  GR_ADMIN = 'GR_ADMIN',
  GR_STAFF_MANAGER = 'GR_STAFF_MANAGER',
  GR_STAFF_USER = 'GR_STAFF_USER',
  GR_CLIENT_MANAGER = 'GR_CLIENT_MANAGER',
  GR_CLIENT_USER = 'GR_CLIENT_USER',
  GR_CLICKER = 'GR_CLICKER',
}

// These reflect real values in the Auth0 Dashboard
export enum Auth0OrganizationName {
  TARGETEDVICTORY = 'targetedvictory',
  TAG = 'tag',
  DEV = 'dev',
  DEV2 = 'dev2',
  STAGING = 'staging',
  STAGING2 = 'staging2',
  OLYMPIC = 'olympic',
  STELLA = 'stella',
  UNIFIN = 'unifin',
  CYGNAL = 'cygnal',
  TMG = 'tmg',
  BELIEVEMEDIA = 'believemedia',
  TWENTYMANOR = 'twentymanor',
  BETTERMOUSETRAP = 'bettermousetrap',
  DONORBUREAU = 'donorbureau',
  METIOR = 'metior',
  DSV2 = 'dsv2',
  TJS = 'tjs',
  SHARED_DEMO = 'shared_demo',
  OPTIMIZE_CONSULTING = 'optimize_consulting',
  AXIOM = 'axiom',
  UNLOCK_SURVEY = 'unlocksurvey',
  JLOFT = 'jloft',
  HICKORY = 'hickory',
}

// These reflect real values in the Auth0 Dashboard
export enum Auth0OrganizationID {
  TARGETEDVICTORY = 'org_RyrIXqMTzAMrg4Ye',
  TAG = 'org_x8haboILqljVFi4M',
  DEMO = 'org_qXo1FFo0f9JOMkr4',
  DEV = 'org_BTE1d1fsotwbdfBL',
  DEV2 = 'org_rgyxRujaq64n3grs',
  STAGING = 'org_Z8jqGOytPMBBFIIA',
  STAGING2 = 'org_nRdDIOYY9L0IEpb4',
  OLYMPIC = 'org_70DonvlZe6I2CTrD',
  STELLA = 'org_WI6jNg2DJJVKcUua',
  UNIFIN = 'org_IU1EuQoTTiUUE6Vt',
  CYGNAL = 'org_tulaMlcFR8GU1UpS',
  TMG = 'org_N96kRTPwJU2WQPxw',
  BELIEVEMEDIA = 'org_KGP83GVnDbBFdtIe',
  TWENTYMANOR = 'org_5VayppCm5WKpNZlO',
  BETTERMOUSETRAP = 'org_IvciHzPG4Ov9Vms9',
  DONORBUREAU = 'org_9sVDFg9lWfpVnSNx',
  METIOR = 'org_HrqYvkfCB3KLGFxt',
  DSV2 = 'org_CSzHIyybIgAYTV4w',
  TJS = 'org_fUFxN6clj6yskkwT',
  SHARED_DEMO = 'org_mfTMaeKznmZRPOFF',
  OPTIMIZE_CONSULTING = 'org_TXRoJ5HqM34avgKP',
  AXIOM = 'org_caHlBBDqvFK0uPsl',
  UNLOCK_SURVEY = 'org_p534q67NLETSCTqT',
  JLOFT = 'org_FYqIXz3bCNTAMsaf',
  HICKORY = 'org_wP3hnLIMAxk9cqvJ',
}

// These reflect real values in the Auth0 Dashboard
export enum Auth0OrganizationAppID {
  TARGETEDVICTORY = '9nwHRQZAhJziVI94Ql14jfNhvNmFscXY',
  TAG = 'VxafznpS3sRpVaFZ4WHt06cWrurde2WV',
  DEMO = 'AZCBwhPaRjgG0nhpPlH3mczNjRwQS4G2',
  DEV = 'Ba6yHD0W15I7lkw0Co91JrKB56T95vBT',
  STAGING = 'ZrR1I5oBDZrtG5LhJ8Cn50TACdmUcRIU',
  SHARED = 'Xr3cn9ZOv1GpyhCnLuqkVPVn1djEwzSJ',
  DONORBUREAU = 'cuPoPP8Uwh5YTZukl6yTm95Sn9w4rtyg'
}

export enum Auth0SubDomain {
  TARGETEDVICTORY = 'targetedvictory',
  TAG = 'tag',
  DONORBUREAU = 'donorbureau',
  DEMO = 'demo',
  DEV = 'dev',
  DEV2 = 'dev2',
  STAGING = 'staging',
  STAGING2 = 'staging2',
  OLYMPIC = 'olympic',
  STELLA = 'stella',
  UNIFIN = 'unifin',
  CYGNAL = 'cygnal',
  TMG = 'tmg',
  BELIEVEMEDIA = 'believemedia',
  TWENTYMANOR = 'twentymanor',
  BETTERMOUSETRAP = 'bettermousetrap',
  METIOR = 'metior',
  DSV2 = 'dsv2',
  TJS = 'tjs',
  LOCAL = 'localhost:4200',
  OPTIMIZE = 'optimize',
  AXIOM = 'axiom',
  UNLOCK_SURVEY = 'unlocksurvey',
  JLOFT = 'jloft',
  HICKORY = 'hickory',
}

// These reflect real values in the Auth0 Dashboard
export enum Auth0ConnectionName {
  DB_USERNAME_PASSWORD = 'Username-Password-Authentication',
}

// Scopes given by Auth0 to authorize with the Management API
export enum Auth0ManagementAPIScope {
  READ_CLIENT_GRANTS = 'read:client_grants',
  CREATE_CLIENT_GRANTS = 'create:client_grants',
  DELETE_CLIENT_GRANTS = 'delete:client_grants',
  UPDATE_CLIENT_GRANTS = 'update:client_grants',
  READ_USERS = 'read:users',
  UPDATE_USERS = 'update:users',
  DELETE_USERS = 'delete:users',
  CREATE_USERS = 'create:users',
  READ_USERS_APP_METADATA = 'read:users_app_metadata',
  UPDATE_USERS_APP_METADATA = 'update:users_app_metadata',
  DELETE_USERS_APP_METADATA = 'delete:users_app_metadata',
  CREATE_USERS_APP_METADATA = 'create:users_app_metadata',
  READ_USER_CUSTOM_BLOCKS = 'read:user_custom_blocks',
  CREATE_USER_CUSTOM_BLOCKS = 'create:user_custom_blocks',
  DELETE_USER_CUSTOM_BLOCKS = 'delete:user_custom_blocks',
  CREATE_USER_TICKETS = 'create:user_tickets',
  READ_CLIENTS = 'read:clients',
  UPDATE_CLIENTS = 'update:clients',
  DELETE_CLIENTS = 'delete:clients',
  CREATE_CLIENTS = 'create:clients',
  READ_CLIENT_KEYS = 'read:client_keys',
  UPDATE_CLIENT_KEYS = 'update:client_keys',
  DELETE_CLIENT_KEYS = 'delete:client_keys',
  CREATE_CLIENT_KEYS = 'create:client_keys',
  READ_CONNECTIONS = 'read:connections',
  UPDATE_CONNECTIONS = 'update:connections',
  DELETE_CONNECTIONS = 'delete:connections',
  CREATE_CONNECTIONS = 'create:connections',
  READ_RESOURCE_SERVERS = 'read:resource_servers',
  UPDATE_RESOURCE_SERVERS = 'update:resource_servers',
  DELETE_RESOURCE_SERVERS = 'delete:resource_servers',
  CREATE_RESOURCE_SERVERS = 'create:resource_servers',
  READ_DEVICE_CREDENTIALS = 'read:device_credentials',
  UPDATE_DEVICE_CREDENTIALS = 'update:device_credentials',
  DELETE_DEVICE_CREDENTIALS = 'delete:device_credentials',
  CREATE_DEVICE_CREDENTIALS = 'create:device_credentials',
  READ_RULES = 'read:rules',
  UPDATE_RULES = 'update:rules',
  DELETE_RULES = 'delete:rules',
  CREATE_RULES = 'create:rules',
  READ_RULES_CONFIGS = 'read:rules_configs',
  UPDATE_RULES_CONFIGS = 'update:rules_configs',
  DELETE_RULES_CONFIGS = 'delete:rules_configs',
  READ_HOOKS = 'read:hooks',
  UPDATE_HOOKS = 'update:hooks',
  DELETE_HOOKS = 'delete:hooks',
  CREATE_HOOKS = 'create:hooks',
  READ_ACTIONS = 'read:actions',
  UPDATE_ACTIONS = 'update:actions',
  DELETE_ACTIONS = 'delete:actions',
  CREATE_ACTIONS = 'create:actions',
  READ_EMAIL_PROVIDER = 'read:email_provider',
  UPDATE_EMAIL_PROVIDER = 'update:email_provider',
  DELETE_EMAIL_PROVIDER = 'delete:email_provider',
  CREATE_EMAIL_PROVIDER = 'create:email_provider',
  BLACKLIST_TOKENS = 'blacklist:tokens',
  READ_STATS = 'read:stats',
  READ_INSIGHTS = 'read:insights',
  READ_TENANT_SETTINGS = 'read:tenant_settings',
  UPDATE_TENANT_SETTINGS = 'update:tenant_settings',
  READ_LOGS = 'read:logs',
  READ_LOGS_USERS = 'read:logs_users',
  READ_SHIELDS = 'read:shields',
  CREATE_SHIELDS = 'create:shields',
  UPDATE_SHIELDS = 'update:shields',
  DELETE_SHIELDS = 'delete:shields',
  READ_ANOMALY_BLOCKS = 'read:anomaly_blocks',
  DELETE_ANOMALY_BLOCKS = 'delete:anomaly_blocks',
  UPDATE_TRIGGERS = 'update:triggers',
  READ_TRIGGERS = 'read:triggers',
  READ_GRANTS = 'read:grants',
  DELETE_GRANTS = 'delete:grants',
  READ_GUARDIAN_FACTORS = 'read:guardian_factors',
  UPDATE_GUARDIAN_FACTORS = 'update:guardian_factors',
  READ_GUARDIAN_ENROLLMENTS = 'read:guardian_enrollments',
  DELETE_GUARDIAN_ENROLLMENTS = 'delete:guardian_enrollments',
  CREATE_GUARDIAN_ENROLLMENTS = 'create:guardian_enrollment_tickets',
  READ_USER_IDP_TOKENS = 'read:user_idp_tokens',
  CREATE_PASSWORDS_CHECKING_JOB = 'create:passwords_checking_job',
  DELETE_PASSWORDS_CHECKING_JOB = 'delete:passwords_checking_job',
  READ_CUSTOM_DOMAINS = 'read:custom_domains',
  DELETE_CUSTOM_DOMAINS = 'delete:custom_domains',
  CREATE_CUSTOM_DOMAINS = 'create:custom_domains',
  UPDATE_CUSTOM_DOMAINS = 'update:custom_domains',
  READ_EMAIL_TEMPLATES = 'read:email_templates',
  CREATE_EMAIL_TEMPLATES = 'create:email_templates',
  UPDATE_EMAIL_TEMPLATES = 'update:email_templates',
  READ_MFA_POLICIES = 'read:mfa_policies',
  UPDATE_MFA_POLICIES = 'update:mfa_policies',
  READ_ROLES = 'read:roles',
  CREATE_ROLES = 'create:roles',
  DELETE_ROLES = 'delete:roles',
  UPDATE_ROLES = 'update:roles',
  READ_PROMPTS = 'read:prompts',
  UPDATE_PROMPTS = 'update:prompts',
  READ_BRANDING = 'read:branding',
  UPDATE_BRANDING = 'update:branding',
  DELETE_BRANDING = 'delete:branding',
  READ_LOG_STREAMS = 'read:log_streams',
  CREATE_LOG_STREAMS = 'create:log_streams',
  DELETE_LOG_STREAMS = 'delete:log_streams',
  UPDATE_LOG_STREAMS = 'update:log_streams',
  CREATE_SIGNING_KEYS = 'create:signing_keys',
  READ_SIGNING_KEYS = 'read:signing_keys',
  UPDATE_SIGNING_KEYS = 'update:signing_keys',
  READ_LIMITS = 'read:limits',
  UPDATE_LIMITS = 'update:limits',
  CREATE_ROLE_MEMBERS = 'create:role_members',
  READ_ROLE_MEMBERS = 'read:role_members',
  DELETE_ROLE_MEMBERS = 'delete:role_members',
  READ_ENTITLEMENTS = 'read:entitlements',
  READ_ATTACK_PROTECTION = 'read:attack_protection',
  UPDATE_ATTACK_PROTECTION = 'update:attack_protection',
  READ_ORGANIZATIONS_SUMMARY = 'read:organizations_summary',
  CREATE_ACTIONS_LOG_SESSIONS = 'create:actions_log_sessions',
  READ_ORGANIZATIONS = 'read:organizations',
  UPDATE_ORGANIZATIONS = 'update:organizations',
  CREATE_ORGANIZATIONS = 'create:organizations',
  DELETE_ORGANIZATIONS = 'delete:organizations',
  CREATE_ORGANIZATION_MEMBERS = 'create:organization_members',
  READ_ORGANIZATION_MEMBERS = 'read:organization_members',
  DELETE_ORGANIZATION_MEMBERS = 'delete:organization_members',
  CREATE_ORGANIZATION_CONNECTIONS = 'create:organization_connections',
  READ_ORGANIZATION_CONNECTIONS = 'read:organization_connections',
  UPDATE_ORGANIZATION_CONNECTIONS = 'update:organization_connections',
  DELETE_ORGANIZATION_CONNECTIONS = 'delete:organization_connections',
  CREATE_ORGANIZATION_MEMBER_ROLES = 'create:organization_member_roles',
  READ_ORGANIZATION_MEMBER_ROLES = 'read:organization_member_roles',
  DELETE_ORGANIZATION_MEMBER_ROLES = 'delete:organization_member_roles',
  CREATE_ORGANIZATION_INVITATIONS = 'create:organization_invitations',
  READ_ORGANIZATION_INVITATIONS = 'read:organization_invitations',
  DELETE_ORGANIZATION_INVITATIONS = 'delete:organization_invitations'
}
