import {
  Auth0Role,
  IExportTCRCampaignPhoneNumbersRequest,
  IHttpResponse,
  ITCRCampaignDetails,
} from '@gr/shared/models';
import { useState } from 'react';
import { axiosPost } from '../../authAxios';
import useProviders from '../../hooks/useProviders';
import useRoles from '../../hooks/useRoles';
import { downloadFileFromUrl } from '../../providers/utility.provider';
import { ButtonVariantEnum } from '../shared/Buttons/types';
import { DetailsPanel } from '../shared/DetailsPanel/DetailsPanel';
import { DetailsPanelSize, IButtonOptions } from '../shared/DetailsPanel/types';
import { IDropdownValue } from '../shared/Form/Dropdown';
import ActiveNumbersCard from './ActiveNumbersCard';
import PurchaseNumbersCard from './PurchaseNumbersCard';
import TCRCampaignForm from './TCRCampaignForm';
import { ITCRCampaignForm } from './types';

interface ITCRCampaignDetailsPanelProps {
  show: boolean;
  loading: boolean;
  toggleLoading: boolean;
  selectedItem?: ITCRCampaignDetails;
  errorMessage?: string;
  onClosePanel: () => void;
  handleSubmit: (formData: ITCRCampaignForm) => Promise<void>;
  handleToggle: () => Promise<void>;
}

const TCRCampaignDetailsPanel = ({
  show,
  loading,
  toggleLoading,
  selectedItem,
  errorMessage,
  onClosePanel,
  handleToggle,
  handleSubmit,
}: ITCRCampaignDetailsPanelProps) => {
  const [{ data: providerData, loading: providerLoading, error: providerError }] = useProviders();
  const [isExporting, setIsExporting] = useState(false);
  const [exportErrorMessage, setExportErrorMessage] = useState(undefined);

  const canExport = useRoles([Auth0Role.GR_ADMIN, Auth0Role.SUPER_ADMIN]);

  const providers: IDropdownValue[] =
    providerData?.data.map((provider) => ({
      label: provider.name ?? '',
      value: provider.id ?? '',
    })) ?? [];

  const exportPhoneNumbers = async () => {
    if (selectedItem) {
      try {
        const { id, clientName, providerName, campaignType } = selectedItem;

        const request: IExportTCRCampaignPhoneNumbersRequest = {
          id,
          clientName,
          providerName,
          campaignType,
        };

        setIsExporting(true);
        const {
          data: { data: signedUrl },
        } = await axiosPost<IHttpResponse<string>>('/tcr-campaigns-phone-numbers-export', request);
        downloadFileFromUrl(signedUrl);
      } catch (err: any) {
        console.error(err);
        setExportErrorMessage(err?.response?.data?.message);
      } finally {
        setIsExporting(false);
      }
    }
  };

  const leftPanelButtonOptions: IButtonOptions[] = [
    {
      text: selectedItem?.isActive ? 'Disable' : 'Enable',
      visible: !!selectedItem,
      onClick: handleToggle,
      variant: selectedItem?.isActive ? ButtonVariantEnum.DELETE : ButtonVariantEnum.SECONDARY,
      loading: toggleLoading,
    },
    {
      text: 'Export Phone Numbers',
      visible: !!selectedItem && canExport,
      onClick: () => exportPhoneNumbers(),
      variant: ButtonVariantEnum.SECONDARY,
      loading: isExporting,
      hideConfirmation: true,
    },
  ];

  return (
    <DetailsPanel
      item={selectedItem}
      title={selectedItem ? 'View TCR Campaign' : 'Add TCR Campaign'}
      formId="tcr-campaign-form"
      size={DetailsPanelSize.Medium}
      show={show}
      closePanel={onClosePanel}
      isReadOnly={!!selectedItem}
      loading={loading}
      errorMessage={exportErrorMessage || errorMessage}
      leftPanelButtonOptions={leftPanelButtonOptions}
    >
      <div>
        <TCRCampaignForm item={selectedItem} onSubmit={handleSubmit} providers={providers} />
        {selectedItem && <PurchaseNumbersCard selectedItem={selectedItem} closePanel={onClosePanel} />}
        {selectedItem && (selectedItem?.activeNumbers ?? 0) > 0 && <ActiveNumbersCard selectedItem={selectedItem} />}
      </div>
    </DetailsPanel>
  );
};

export default TCRCampaignDetailsPanel;
