import { IContactListEntity, IGetContactListBreakdownResponse } from '@gr/shared/models';
import { Field, Formik } from 'formik';
import { Link } from 'react-router-dom';
import { OldFileUpload } from '../shared/Form/OldFileUpload';
import { IRadioButtonOption, RadioButtonGroup } from '../shared/Form/RadioButton';
import { TextInput } from '../shared/Form/TextInput';
import { contactListFormSchema } from './types';

export interface IContactListForm {
  name: string;
  isActive?: IRadioButtonOption;
  contactList?: any;
}

const options: IRadioButtonOption[] = [
  {
    label: 'Enabled',
    value: true,
  },
  {
    label: 'Disabled',
    value: false,
  },
];

interface IContactListFormProps {
  item: IContactListEntity | undefined;
  breakdownInfo?: IGetContactListBreakdownResponse[];
  onSubmit?: (formValues: IContactListForm) => void;
}

const ContactListForm = ({ item, breakdownInfo, onSubmit }: IContactListFormProps): JSX.Element => {
  const initialFormState: IContactListForm = {
    name: item?.name ?? '',
    isActive:
      item?.isActive !== null && item?.isActive !== undefined
        ? options.find((option) => option.value === item?.isActive)
        : options[0],
    contactList: undefined,
  };

  return (
    <>
      <Formik
        initialValues={initialFormState}
        validationSchema={contactListFormSchema}
        onSubmit={async (values) => {
          onSubmit && onSubmit(values);
        }}
      >
        {({ values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue, setFieldTouched }) => (
          // Set form id so external submit button can still work
          <>
            <form id="contact-form" onSubmit={handleSubmit}>
              <div className="flex flex-col space-y-4">
                <TextInput
                  id="name"
                  name="name"
                  label="Name"
                  value={values.name}
                  error={touched.name ? errors.name : ''}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />

                <Field
                  name="isActive"
                  component={RadioButtonGroup}
                  options={options}
                  label="Status"
                  error={touched.isActive && (errors.isActive as any)?.value}
                  value={values.isActive}
                  onBlur={handleBlur}
                  onChange={(isActive: IRadioButtonOption) => {
                    setFieldTouched('isActive');
                    setFieldValue('isActive', isActive);
                  }}
                />

                <OldFileUpload
                  id="contactList"
                  name="contactList"
                  label="Add Contacts"
                  accepts=".csv"
                  value={values.contactList}
                  error={touched.contactList && (errors.contactList as any)}
                  onBlur={handleBlur}
                  placeholder={item?.originalFileName ?? 'Select a .csv'}
                  onChange={(fileUploadEvent: any) => {
                    const file = fileUploadEvent.target.files[0];
                    setFieldTouched('contactList');
                    setFieldValue('contactList', file);
                    if (!values.name) {
                      const displayName = file.name.split('.csv')[0].replace(/_/g, ' ');
                      // If no name has been provided, default it to the filename
                      setFieldValue('name', `${displayName.charAt(0).toUpperCase()}${displayName.slice(1)}`);
                    }
                  }}
                />

                <div className="mt-2">
                  <p className="text-sm text-gray-500">Each row must have a phone number.</p>
                  <p className="text-sm text-gray-500">
                    Download the template{' '}
                    <Link
                      className="font-semibold text-sky-500 hover:underline"
                      to="/sample_upload_contacts.csv"
                      target="_blank"
                      download
                    >
                      here
                    </Link>
                  </p>
                </div>
              </div>
            </form>
            {/* <ul>
              {values.contactList?.map((val: any) => (
                <li>test</li>
              ))}
            </ul> */}
          </>
        )}
      </Formik>
    </>
  );
};

export default ContactListForm;
