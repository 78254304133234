import { CampaignStatusEnum } from '@gr/shared/enums';
import { ICampaignDetails } from '@gr/shared/models';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import { Exclamation } from '../../shared/Icons/Exclamation';
import { Time as TimeIcon } from '../../shared/Icons/Time';

interface ICampaignTitleProps {
  campaign: ICampaignDetails;
}

export const CampaignTitle = ({ campaign }: ICampaignTitleProps) => {
  const history = useHistory();
  return (
    <div>
      {campaign.totalTestMessagesFailed > 0 && (
        <div title={'This campaign failed to send some test messages.'} className="mr-4">
          <Exclamation />
        </div>
      )}
      <div>
        <h5 className="mb-1 text-medium-gray">{campaign.clientName}</h5>
        <h3
          onClick={() => {
            if (campaign.status === CampaignStatusEnum.DRAFT) {
              history.push(`/app/campaigns/${campaign.id}/edit`);
            } else {
              history.push(`/app/campaigns/${campaign.id}`);
            }
          }}
          title={campaign.name}
          className="max-w-lg mb-3 text-black break-words whitespace-normal cursor-pointer hover:underline hover:text-wc-blue"
        >
          {campaign.name}
        </h3>
        <div className="flex items-center mt-2">
          <TimeIcon />
          <div className="ml-2 body-text-small text-medium-gray">{moment(campaign.startsAt).format('L, LT')}</div>
        </div>
      </div>
    </div>
  );
};
