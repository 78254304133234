import { IColumn, IColumnClickConfig } from '@Wonder-Cave/ui';
import { EntityName } from '@gr/shared/enums';
import { Auth0Role, FilterDataTypeEnum, ISearchRequest, SearchSortDirectionEnum } from "@gr/shared/models";
import { isAdmin, isClicker } from "@gr/shared/utils";
import * as yup from 'yup';
import { userTypeDropdownValues } from "../SystemNumbers/types";
import { IDropdownValue } from "../shared/Form/Dropdown";
import { dropdownSchema } from "../shared/Schema/yup";
import { IFilterDropdownOptions } from "../shared/Table/FilterForm";

export const defaultUsersTableOptions: ISearchRequest = {
  entity: EntityName.USERS,
  filters: [],
  pagination: {
    skip: 0,
    take: 25,
  },
  sort: {
    fieldName: 'given_name',
    sortDirection: SearchSortDirectionEnum.DESC,
  },
};

export const getUsersColumns = (columnClickEvents: IColumnClickConfig, columnRenders: any): IColumn[] => {
  const columns = [
    {
      headerName: 'Name',
      fieldName: 'name',
      fieldType: FilterDataTypeEnum.STRING,
      renderColumn: columnRenders['name'],
      onColumnClick: columnClickEvents['name']
    },
    // {
    //   headerName: 'Email',
    //   fieldName: 'email',
    //   fieldType: FilterDataTypeEnum.STRING,
    //   isRowHeader: true,
    // },
    // {
    //   headerName: 'Access',
    //   fieldName: 'blocked',
    //   fieldType: FilterDataTypeEnum.BOOLEAN,
    //   renderColumn: columnRenders['blocked'],
    //   sortable: false
    // },
    {
      headerName: 'Clients',
      fieldName: 'clients',
      fieldType: FilterDataTypeEnum.STRING,
      renderColumn: columnRenders['clients']
    },
    {
      headerName: 'Created At',
      fieldName: 'created_at',
      fieldType: FilterDataTypeEnum.DATE,
      renderColumn: columnRenders['createdAt']
    },
    {
      headerName: 'Requested By',
      fieldName: 'requestedBy',
      fieldType: FilterDataTypeEnum.STRING,
      renderColumn: columnRenders['requestedBy']
    },
    {
      headerName: '',
      fieldName: 'actions',
      fieldType: FilterDataTypeEnum.ACTIONS
    }
  ];

  return columns;
};

export const usersFilterDropdownOptions: IFilterDropdownOptions = {
  type: userTypeDropdownValues,
};

export interface IUserForm {
  email: string,
  firstName: string,
  lastName: string,
  organization: IDropdownValue | undefined,
  role: IDropdownValue;
  clickerGroupId: string | null | undefined;
  clients: IDropdownValue[];
}

export const userFormSchema: yup.SchemaOf<IUserForm> = yup.object().shape({
  email: yup.string().email('Invalid email format').defined('Required'),
  firstName: yup.string().defined('Required'),
  lastName: yup.string().defined('Required'),
  organization: yup
    .object()
    .shape({
      label: yup.string().defined('Required'),
      value: yup.mixed().defined('Required'),
      additionalData: yup.mixed()
    }).required('Required'),
  role: yup
    .object()
    .shape({
      label: yup.string().defined('Required'),
      value: yup.mixed().defined('Required'),
      additionalData: yup.mixed()
    }).required('Required'),
  clickerGroupId: yup.string().nullable().when('role.value', {
    is: (value: Auth0Role) => [Auth0Role.STAFF, Auth0Role.GR_CLICKER].includes(value),
    then: yup.string().defined('Required')
  }),
  clients: yup.array().of(dropdownSchema)
    .when('role', {
      is: (role: IDropdownValue) => !isAdmin(role?.value) && !isClicker(role?.value),
      then: yup.array().of(dropdownSchema).min(1, 'Select at least one client').required("Must select at least one client"),
      otherwise: yup.array().of(dropdownSchema).optional(),
    })
});