import { IColumn } from '@Wonder-Cave/ui';
import { BUCKETS, EntityName } from '@gr/shared/enums';
import {
  FilterDataTypeEnum,
  FilterOperatorEnum,
  ISearchRequest,
  ISignedUrlRequest,
  SearchSortDirectionEnum
} from '@gr/shared/models';
import * as yup from 'yup';
import { getCsvSample } from '../../providers/validation.provider';
import { IContactListForm } from './ContactListForm';

export const defaultContactListTableOptions: ISearchRequest = {
  entity: EntityName.CONTACT_LISTS,
  filters: [],
  pagination: {
    skip: 0,
    take: 25,
  },
  sort: {
    fieldName: 'createdAt',
    sortDirection: SearchSortDirectionEnum.DESC,
  },
};

export const allContactListSearchOptions: ISearchRequest = {
  entity: EntityName.CONTACT_LISTS,
  filters: [],
  pagination: {
    skip: 0,
    take: 10000,
  },
  sort: {
    fieldName: 'name',
    sortDirection: SearchSortDirectionEnum.ASC,
  },
};

export const allEnabledClassifiedContactListSearchOptions: ISearchRequest = {
  entity: EntityName.CONTACT_LISTS,
  filters: [
    {
      dataType: FilterDataTypeEnum.BOOLEAN,
      fieldName: 'isActive',
      operator: FilterOperatorEnum.EQUALS,
      value: true,
    },
    {
      dataType: FilterDataTypeEnum.BOOLEAN,
      fieldName: 'isClassified',
      operator: FilterOperatorEnum.EQUALS,
      value: true,
    },
  ],
  pagination: {
    skip: 0,
    take: 10,
  },
  sort: {
    fieldName: 'name',
    sortDirection: SearchSortDirectionEnum.ASC,
  },
};

export const getColumns = (columnRenders: any): IColumn[] => {
  return [
    {
      headerName: 'Contact List Name',
      fieldName: 'name',
      fieldType: FilterDataTypeEnum.STRING,
      isRowHeader: true,
      renderColumn: columnRenders['name']
    },
    {
      headerName: 'Status',
      fieldName: 'status',
      fieldType: FilterDataTypeEnum.STRING, // todo 3655: implement some logic to track contact list classification if in classifying status
    },
    {
      headerName: 'Created At',
      fieldName: 'createdAt',
      fieldType: FilterDataTypeEnum.DATE,
    },
    {
      headerName: 'Last Updated Date',
      fieldName: 'updatedAt',
      fieldType: FilterDataTypeEnum.DATE,
    },
    {
      headerName: 'Actions',
      fieldName: 'actions',
      fieldType: FilterDataTypeEnum.ACTIONS
    }
  ];
};

export const s3UrlRequest: ISignedUrlRequest = {
  bucket: BUCKETS.CONTACTS,
  key: '',
  action: 'putObject',
  contentType: 'text/csv',
};

// TODO: Turn this into a call with validation options
const validateCsvHeaders = async (file: File) => {
  const INVALID_CSV_HEADERS = 'CSV does not contain the proper headers';
  const NO_DATA = 'CSV must contain "Phone" data';

  try {
    const rows = await getCsvSample(file);
    if (!rows.length) {
      return INVALID_CSV_HEADERS;
    }

    const headers = rows[0];
    const lowerCaseHeaders = headers.map(h => h.toLocaleLowerCase());

    if (headers.length < 1 || !lowerCaseHeaders.includes('phone')) {
      return INVALID_CSV_HEADERS;
    }

    if (rows.length > 1) {
      const phoneIdx = rows[0].findIndex(c => c.toLocaleLowerCase() === 'phone');

      const sample = rows.slice(1).reduce((list, row) => {
        row.forEach((cell, idx) => {
          if (idx === phoneIdx) {
            list.push(cell?.toLocaleLowerCase()?.trim());
          }
        });
        return list;
      }, []);

      if (!sample.some(c => !!c)) {
        return NO_DATA;
      }
    }

    return null;
  } catch (err) {
    console.error(err);
    return INVALID_CSV_HEADERS;
  }
};

export const contactListFormSchema: yup.SchemaOf<IContactListForm> = yup.object().shape({
  name: yup.string().defined('Required'),
  isActive: yup
    .object()
    .shape({
      label: yup.string(),
      value: yup.boolean().required('Required'),
    })
    .nullable(),
  contactList: yup
    .mixed()
    // If there's no file, no need to run validation
    .test('csvValidate', 'Uploaded file must be .csv', (file: File): boolean => {
      return file ? file.name?.endsWith('.csv') : true;
    })
    .test({
      name: 'csvHeaderValidate',
      test: async function (file: File) {
        const errMsg = file ? await validateCsvHeaders(file) : null;
        return errMsg
          ? this.createError({
            message: errMsg,
            path: 'contactList'
          })
          : true;
      }
    }),
});

export const breakdownColumns = [
  {
    headerName: 'Type',
    fieldName: 'type',
    fieldType: FilterDataTypeEnum.ENUM,
  },
  {
    headerName: 'Count',
    fieldName: 'count',
    fieldType: FilterDataTypeEnum.NUMBER,
  },
];

export const timezoneColumns = [
  {
    headerName: 'Timezone',
    fieldName: 'timezone',
    fieldType: FilterDataTypeEnum.STRING,
  },
  {
    headerName: 'Count',
    fieldName: 'count',
    fieldType: FilterDataTypeEnum.NUMBER,
  },
];
