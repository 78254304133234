import { IColumn, IStaticColumn } from '@Wonder-Cave/ui';
import { CampaignStatusEnum, CampaignTypeEnum, EntityName, MessageTypeEnum } from '@gr/shared/enums';
import {
  FilterDataTypeEnum,
  ISearchRequest,
  SearchSortDirectionEnum,
} from '@gr/shared/models';
import { checkSHAFTCompliance } from '@gr/shared/utils';
import * as yup from 'yup';
import { isFileLocalSize, isFileMediaType } from '../../providers/validation.provider';
import { campaignTypeDropdownValues, messageTypeDropdownValues } from '../SystemNumbers/types';
import { IDropdownValue } from '../shared/Form/Dropdown';
import { IRadioButtonOption } from '../shared/Form/RadioButton';
import { IFilterDropdownOptions } from '../shared/Table/FilterForm';
import { IColumn as IColumnOld } from '../shared/Table/types';

export const defaultCampaignsTableOptions: ISearchRequest = {
  entity: EntityName.CAMPAIGNS,
  filters: [],
  pagination: {
    skip: 0,
    take: 10,
  },
  sort: {
    fieldName: 'createdAt',
    sortDirection: SearchSortDirectionEnum.DESC,
  },
};

export const getColumns = (columnRenders: any): IColumn[] => {
  return [
    {
      headerName: 'Title',
      fieldName: 'name',
      fieldType: FilterDataTypeEnum.STRING,
      isRowHeader: true,
      renderColumn: columnRenders['title'],
      className: 'max-w-[200px] text-ellipsis overflow-hidden'
    },
    {
      headerName: 'Campaign',
      fieldName: 'type',
      fieldType: FilterDataTypeEnum.ENUM,
    },
    {
      headerName: 'Status',
      fieldName: 'status',
      fieldType: FilterDataTypeEnum.ENUM,
      renderColumn: columnRenders['status'],
    },
    {
      headerName: 'Type',
      fieldName: 'messageType',
      fieldType: FilterDataTypeEnum.ENUM,
    },
    {
      headerName: 'Progress',
      fieldName: 'deliveredProgress',
      fieldType: FilterDataTypeEnum.STRING,
      renderColumn: columnRenders['deliveredProgress'],
      description: 'Sent / Delivered / Failed'
    },
    {
      headerName: 'Actions',
      fieldName: 'actions',
      fieldType: FilterDataTypeEnum.ACTIONS
    }
  ];
};

export const messageTypeOptions: IRadioButtonOption[] = [
  {
    label: 'SMS',
    value: MessageTypeEnum.SMS,
    description: 'Short Message Service.',
  },
  {
    label: 'MMS',
    value: MessageTypeEnum.MMS,
    description: 'Multimedia Messaging Service. This requires a media file upload.',
  },
];

export const getErrorColumnsOld = (showColumn: boolean): IColumnOld[] => {
  const columns = [
    {
      headerName: 'Code',
      fieldName: 'code',
      fieldType: FilterDataTypeEnum.STRING,
      isRowHeader: true,
    },
    {
      headerName: 'Count',
      fieldName: 'count',
      fieldType: FilterDataTypeEnum.STRING,
    },
    {
      headerName: 'Error Type',
      fieldName: 'errorType',
      fieldType: FilterDataTypeEnum.STRING,
    },
  ];

  if (showColumn) {
    columns.push({
      headerName: 'Billable',
      fieldName: 'billing',
      fieldType: FilterDataTypeEnum.BOOLEAN,
    });
  }

  return columns;
};

export const getErrorColumns = (showColumn: boolean): IStaticColumn[] => {
  const columns = [
    {
      headerName: 'Code',
      fieldName: 'code',
    },
    {
      headerName: 'Count',
      fieldName: 'count',
    },
    {
      headerName: 'Error Type',
      fieldName: 'errorType',
    },
  ];
  if (showColumn) {
    columns.push({
      headerName: 'Billable',
      fieldName: 'billing',
    });
  }

  return columns;
};

export const getTestMessageColumns = (showColumn: boolean): IColumnOld[] => {
  const columns = [
    {
      headerName: 'Number',
      fieldName: 'toNumber',
      fieldType: FilterDataTypeEnum.NUMBER,
      isRowHeader: true,
    },
    {
      headerName: 'Carrier',
      fieldName: 'carrierName',
      fieldType: FilterDataTypeEnum.STRING,
      isRowHeader: false,
    },
    {
      headerName: 'Status',
      fieldName: 'status',
      fieldType: FilterDataTypeEnum.ENUM,
      isRowHeader: false,
    },
    {
      headerName: 'Error Code',
      fieldName: 'errorCode',
      fieldType: FilterDataTypeEnum.STRING,
      isRowHeader: false,
    },
    {
      headerName: 'Error Type',
      fieldName: 'errorType',
      fieldType: FilterDataTypeEnum.STRING,
      isRowHeader: false,
    }
  ];

  if (showColumn) {
    columns.push({
      headerName: 'Provider Error Code',
      fieldName: 'providerErrorCode',
      fieldType: FilterDataTypeEnum.STRING,
      isRowHeader: false,
    });

    columns.push({
      headerName: 'Provider Error Type',
      fieldName: 'providerErrorType',
      fieldType: FilterDataTypeEnum.STRING,
      isRowHeader: false,
    });
  }

  return columns;
};

export interface ICampaignForm {
  id?: string;
  name: string;
  status: CampaignStatusEnum;
  message: string;
  client?: IDropdownValue;
  contactLists: IDropdownValue[];
  showSuppressions?: boolean;
  suppressionLists: IDropdownValue[];
  suppressionAreaCodes: IDropdownValue[];
  suppressionStates: IDropdownValue[];
  clickerGroup?: string | null;
  messageType: MessageTypeEnum;
  startsAt: Date | undefined;
  endsAt: Date | undefined;
  externalId?: string;
  mediaFile?: any;
  mediaName?: string;
  isMMSVideo?: boolean;
  mediaUrl?: string;
  sendTestMessages?: boolean;
  clickTrackingEnabled?: boolean;
  twoWayEnabled?: boolean;
  generateUniqueLinks?: boolean;
  campaignType: CampaignTypeEnum | undefined;
  url?: string;
  domain?: string;
  domainId?: string;
  timezonePrioritizationEnabled?: boolean;
}

export interface ICampaignPlanForm {
  name: string;
  client?: IDropdownValue;
  contactLists: IDropdownValue[];
  suppressionLists: IDropdownValue[];
  suppressionAreaCodes: IDropdownValue[];
  suppressionStates: IDropdownValue[];
  clickerGroup?: string | null;
  messageType?: IRadioButtonOption;
  externalId?: string;
  campaignType: CampaignTypeEnum;
}

export interface ICampaignBuildForm {
  message: string;
}

export const campaignFormSchema: yup.SchemaOf<ICampaignForm> = yup.object().shape({
  id: yup.string(),
  name: yup.string().defined('Required'),
  status: yup.mixed<CampaignStatusEnum>().defined('Required').required('Required'),
  message: yup
    .string()
    .test('notContainsPipe', 'Message must not contain "|" symbol', (message) => !(message && message.includes('|')))
    .when('messageType', {
      is: (messageType: IDropdownValue) => messageType.value === MessageTypeEnum.MMS,
      then: yup.string().max(1600, 'Message cannot be longer than 1600 characters'),
      otherwise: yup.string().max(1000, 'Message cannot be longer than 1000 characters'),
    })
    .test('containsLink', 'Message must contain {{link}}', (message: string | undefined, context): boolean => {
      return context.parent.clickTrackingEnabled && message ? message?.toLowerCase()?.includes('{{link}}') : true;
    })
    .test('notContainsLink', 'Message must not contain {{link}}', (message: string | undefined, context): boolean => {
      return !context.parent.clickTrackingEnabled && message ? !message?.toLowerCase()?.includes('{{link}}') : true;
    })
    .test('shaftCompliance', 'Please update your message to remove banned words.', (message: string | undefined, context): boolean => {
      return checkSHAFTCompliance(message);
    })
    .defined('Required'),
  client: yup
    .object()
    .shape({
      label: yup.string().defined('Required'),
      value: yup.mixed().defined('Required'),
    })
    .nullable(),
  contactLists: yup
    .array()
    .of(
      yup.object().shape({
        label: yup.string().defined(),
        value: yup.mixed().required('Required'),
        additionalData: yup.mixed(),
      })
    )
    .min(1, 'Pick at least one Contact List'),
  showSuppressions: yup.boolean(),
  suppressionLists: yup
    .array().of(
      yup.object().shape({
        label: yup.string().defined(),
        value: yup.mixed().required('Required'),
        additionalData: yup.mixed(),
      })
    ).when({
      is: ((contactList: IDropdownValue[]) => contactList.length <= 0),
      then: yup.array().max(0)
    }),
  suppressionAreaCodes: yup.array()
    .of(
      yup.object().shape({
        label: yup.string().defined(),
        value: yup.mixed().required('Required'),
        additionalData: yup.mixed(),
      })
    ),
  suppressionStates: yup.array()
    .of(
      yup.object().shape({
        label: yup.string().defined(),
        value: yup.mixed().required('Required'),
        additionalData: yup.mixed(),
      })
    ),
  clickerGroup: yup.string().when('client', {
    is: (client: IDropdownValue) => !!client?.value ? !client?.additionalData?.clickerGroup : false,
    then: yup.string().typeError('Required').min(0, 'Required'), // Allows an empty string (None value)
    otherwise: yup.string().required('Required')
  }),
  messageType: yup
    .object()
    .shape({
      label: yup.string(),
      value: yup.string().required('Required'),
    })
    .nullable(),
  startsAt: yup
    .date()
    .min(new Date(), 'Campaign cannot start in the past')
    .typeError('Invalid Date')
    .defined('Required')
  ,
  endsAt: yup
    .date()
    .typeError('Invalid Date')
    .defined('Required')
    .min(yup.ref('startsAt'), 'End date cannot be before Start date'),
  externalId: yup.string(),
  mediaUrl: yup.string().when('messageType', {
    is: (messageType: MessageTypeEnum) => messageType === MessageTypeEnum.MMS,
    then: yup.string().defined('Required'),
    otherwise: yup.string().nullable()
  }),
  mediaName: yup.string(),
  isMMSVideo: yup.boolean(),
  mediaFile: yup.mixed().when('messageType', {
    is: (messageType: MessageTypeEnum) => messageType === MessageTypeEnum.MMS,
    then: yup
      .mixed()
      .required('Required')
      .test('fileTypeValidate', 'Uploaded file must be image or video', (file: File): boolean =>
        isFileMediaType(file)
      )
      .test('fileSize', 'File is too large. File must be smaller than 850kb', (file: File): boolean =>
        isFileLocalSize(file)
      )
    // then: yup.mixed().when('numberType', {
    //   is: (numberType: SystemNumberTypeEnum) => numberType === SystemNumberTypeEnum.LOCAL,
    //   then: yup
    //     .mixed()
    //     .required('Required')
    //     .test('fileTypeValidate', 'Uploaded file must be image or video', (file: File): boolean =>
    //       isFileMediaType(file)
    //     )
    //     .test('fileSize', 'File is too large. File must be smaller than 850kb', (file: File): boolean =>
    //       isFileLocalSize(file)
    //     ),
    //   otherwise: yup
    //     .mixed()
    //     .required('Required')
    //     .test('fileTypeValidate', 'Uploaded file must be image or video', (file: File): boolean =>
    //       isFileMediaType(file)
    //     )
    //     .test('fileSize', 'File is too large. File must be smaller than 525kb', (file: File): boolean =>
    //       isFileTollFreeSize(file)
    //     ),
    // }),
  }),
  sendTestMessages: yup.boolean(),
  clickTrackingEnabled: yup.boolean(),
  twoWayEnabled: yup.boolean(),
  generateUniqueLinks: yup.boolean(),
  campaignType: yup.mixed<CampaignTypeEnum>().defined('Required').required('Required'),
  url: yup.string().when('clickTrackingEnabled', {
    is: (clickTrackingEnabled: boolean) => clickTrackingEnabled,
    then: yup.string().test('apostrophe', 'URL cannot have an apostrophe', (value) => !value?.includes('\'')).url('Invalid URL format').defined('Required'),
  }),
  domainId: yup.string().when('clickTrackingEnabled', {
    is: (clickTrackingEnabled: boolean) => clickTrackingEnabled,
    then: yup.string().defined('Required'),
  }),
  domain: yup.string(),
  timezonePrioritizationEnabled: yup.boolean(),
});

export const campaignStatusDropdownValues: IDropdownValue[] = Object.keys(CampaignStatusEnum)
  .filter((val) => typeof val === 'string')
  .map((val) => ({
    label: CampaignStatusEnum[val]
      .split('_')
      .map((fragment) => fragment.charAt(0).toLocaleUpperCase() + fragment.slice(1).toLocaleLowerCase())
      .join(' '),
    value: val,
  }));

export const campaignFilterDropdownOptions: IFilterDropdownOptions = {
  messageType: messageTypeDropdownValues,
  type: campaignTypeDropdownValues,
  status: campaignStatusDropdownValues,
  clientName: [],
};
