import { Auth0OrganizationAppID, Auth0OrganizationID, Auth0OrganizationName, Auth0SubDomain } from '@gr/shared/models';

// TODO: Move this to a Configuration DB
const AUTH0_CONFIG_MAP = createAuth0ConfigMap();

export interface Auth0Config {
  label: string;
  clientId: Auth0OrganizationAppID;
  organization: Auth0OrganizationName,
  organizationId: Auth0OrganizationID;
  apiUrl: string;
  websocketUrl: string;
  redirectUrl: string;
}

export function getOrganizationLabelByOrg(organization: Auth0OrganizationName) {
  return getConfigByOrgName(organization)?.label;
}

export function getSubDomainLabel(subDomain: Auth0SubDomain) {
  return getConfigByDomain(subDomain)?.label;
}

export function getAuth0Organization() {
  return getConfigByDomain()?.organization;
}

export function getAuth0Config() {
  return getConfigByDomain();
}

export function getWebSocketUrl() {
  return getConfigByDomain()?.websocketUrl;
}

export function getApiUrl() {
  return getConfigByDomain()?.apiUrl;
}

export function goToSubDomain(subDomain: Auth0SubDomain) {
  const url = AUTH0_CONFIG_MAP.get(subDomain)?.redirectUrl;
  window.location.replace(url + window?.location?.pathname);
}

export function getSubDomain() {
  return <Auth0SubDomain>window?.location?.host?.split('.')?.[0];
}

export function getSubDomainByOrg(organization: Auth0OrganizationName) {
  return [...AUTH0_CONFIG_MAP.entries()]
    ?.find(([subDomain, config]) => config?.organization === organization)
    ?.[0];
}

export function getConfigByDomain(subDomain?: Auth0SubDomain) {
  return <Auth0Config>AUTH0_CONFIG_MAP.get(subDomain ?? getSubDomain());
}

export function getConfigByOrgName(organization?: Auth0OrganizationName) {
  const configs = [...AUTH0_CONFIG_MAP.values()];

  return !!organization
    ? configs?.find(config => config?.organization === organization)
    : getConfigByDomain();
}

export function getConfigByOrgID(organizationId?: string) {
  const configs = [...AUTH0_CONFIG_MAP.values()];

  return !!organizationId
    ? configs?.find(config => config?.organizationId === organizationId)
    : getConfigByDomain();
}

function createAuth0ConfigMap() {
  const map: Map<Auth0SubDomain, Auth0Config> = new Map();

  map.set(Auth0SubDomain.OLYMPIC, {
    label: 'Olympic',
    clientId: Auth0OrganizationAppID.SHARED,
    organization: Auth0OrganizationName.OLYMPIC,
    organizationId: Auth0OrganizationID.OLYMPIC,
    apiUrl: `https://api.shared.grassrootstexts.com`,
    websocketUrl: 'wss://ws.shared.grassrootstexts.com',
    redirectUrl: `https://olympic.grassrootstexts.com`,
  });

  map.set(Auth0SubDomain.STELLA, {
    label: 'Stella',
    clientId: Auth0OrganizationAppID.SHARED,
    organization: Auth0OrganizationName.STELLA,
    organizationId: Auth0OrganizationID.STELLA,
    apiUrl: `https://api.shared.grassrootstexts.com`,
    websocketUrl: 'wss://ws.shared.grassrootstexts.com',
    redirectUrl: `https://stella.grassrootstexts.com`,
  });

  map.set(Auth0SubDomain.UNIFIN, {
    label: 'Unifin',
    clientId: Auth0OrganizationAppID.SHARED,
    organization: Auth0OrganizationName.UNIFIN,
    organizationId: Auth0OrganizationID.UNIFIN,
    apiUrl: `https://api.shared.grassrootstexts.com`,
    websocketUrl: 'wss://ws.shared.grassrootstexts.com',
    redirectUrl: `https://unifin.grassrootstexts.com`,
  });

  map.set(Auth0SubDomain.CYGNAL, {
    label: 'Cygnal',
    clientId: Auth0OrganizationAppID.SHARED,
    organization: Auth0OrganizationName.CYGNAL,
    organizationId: Auth0OrganizationID.CYGNAL,
    apiUrl: `https://api.shared.grassrootstexts.com`,
    websocketUrl: 'wss://ws.shared.grassrootstexts.com',
    redirectUrl: `https://cygnal.grassrootstexts.com`,
  });

  map.set(Auth0SubDomain.TMG, {
    label: 'Thomas Media Group',
    clientId: Auth0OrganizationAppID.SHARED,
    organization: Auth0OrganizationName.TMG,
    organizationId: Auth0OrganizationID.TMG,
    apiUrl: `https://api.shared.grassrootstexts.com`,
    websocketUrl: 'wss://ws.shared.grassrootstexts.com',
    redirectUrl: `https://tmg.grassrootstexts.com`,
  });

  map.set(Auth0SubDomain.BELIEVEMEDIA, {
    label: 'Believe Media',
    clientId: Auth0OrganizationAppID.SHARED,
    organization: Auth0OrganizationName.BELIEVEMEDIA,
    organizationId: Auth0OrganizationID.BELIEVEMEDIA,
    apiUrl: `https://api.shared.grassrootstexts.com`,
    websocketUrl: 'wss://ws.shared.grassrootstexts.com',
    redirectUrl: `https://believemedia.grassrootstexts.com`,
  });

  map.set(Auth0SubDomain.TWENTYMANOR, {
    label: 'Twenty Manor',
    clientId: Auth0OrganizationAppID.SHARED,
    organization: Auth0OrganizationName.TWENTYMANOR,
    organizationId: Auth0OrganizationID.TWENTYMANOR,
    apiUrl: `https://api.shared.grassrootstexts.com`,
    websocketUrl: 'wss://ws.shared.grassrootstexts.com',
    redirectUrl: `https://twentymanor.grassrootstexts.com`,
  });

  map.set(Auth0SubDomain.BETTERMOUSETRAP, {
    label: 'Better Mousetrap',
    clientId: Auth0OrganizationAppID.SHARED,
    organization: Auth0OrganizationName.BETTERMOUSETRAP,
    organizationId: Auth0OrganizationID.BETTERMOUSETRAP,
    apiUrl: `https://api.shared.grassrootstexts.com`,
    websocketUrl: 'wss://ws.shared.grassrootstexts.com',
    redirectUrl: `https://bettermousetrap.grassrootstexts.com`,
  });

  map.set(Auth0SubDomain.METIOR, {
    label: 'Metior, Inc.',
    clientId: Auth0OrganizationAppID.SHARED,
    organization: Auth0OrganizationName.METIOR,
    organizationId: Auth0OrganizationID.METIOR,
    apiUrl: `https://api.shared.grassrootstexts.com`,
    websocketUrl: 'wss://ws.shared.grassrootstexts.com',
    redirectUrl: `https://metior.grassrootstexts.com`,
  });

  map.set(Auth0SubDomain.DSV2, {
    label: 'DSV2',
    clientId: Auth0OrganizationAppID.SHARED,
    organization: Auth0OrganizationName.DSV2,
    organizationId: Auth0OrganizationID.DSV2,
    apiUrl: `https://api.shared.grassrootstexts.com`,
    websocketUrl: 'wss://ws.shared.grassrootstexts.com',
    redirectUrl: `https://dsv2.grassrootstexts.com`,
  });

  map.set(Auth0SubDomain.TJS, {
    label: 'TJS Investments',
    clientId: Auth0OrganizationAppID.SHARED,
    organization: Auth0OrganizationName.TJS,
    organizationId: Auth0OrganizationID.TJS,
    apiUrl: `https://api.shared.grassrootstexts.com`,
    websocketUrl: 'wss://ws.shared.grassrootstexts.com',
    redirectUrl: `https://tjs.grassrootstexts.com`,
  });

  map.set(Auth0SubDomain.OPTIMIZE, {
    label: 'Optimize Consulting',
    clientId: Auth0OrganizationAppID.SHARED,
    organization: Auth0OrganizationName.OPTIMIZE_CONSULTING,
    organizationId: Auth0OrganizationID.OPTIMIZE_CONSULTING,
    apiUrl: `https://api.shared.grassrootstexts.com`,
    websocketUrl: 'wss://ws.shared.grassrootstexts.com',
    redirectUrl: `https://optimize.grassrootstexts.com`,
  });

  map.set(Auth0SubDomain.DEMO, {
    label: 'DEMO',
    clientId: Auth0OrganizationAppID.SHARED,
    organization: Auth0OrganizationName.SHARED_DEMO,
    organizationId: Auth0OrganizationID.SHARED_DEMO,
    apiUrl: `https://api.shared.grassrootstexts.com`,
    websocketUrl: 'wss://ws.shared.grassrootstexts.com',
    redirectUrl: `https://demo.grassrootstexts.com`,
  });

  map.set(Auth0SubDomain.AXIOM, {
    label: 'Axiom Strategies',
    clientId: Auth0OrganizationAppID.SHARED,
    organization: Auth0OrganizationName.AXIOM,
    organizationId: Auth0OrganizationID.AXIOM,
    apiUrl: `https://api.shared.grassrootstexts.com`,
    websocketUrl: 'wss://ws.shared.grassrootstexts.com',
    redirectUrl: `https://axiom.grassrootstexts.com`,
  });

  map.set(Auth0SubDomain.UNLOCK_SURVEY, {
    label: 'Unlock Surveys',
    clientId: Auth0OrganizationAppID.SHARED,
    organization: Auth0OrganizationName.UNLOCK_SURVEY,
    organizationId: Auth0OrganizationID.UNLOCK_SURVEY,
    apiUrl: `https://api.shared.grassrootstexts.com`,
    websocketUrl: 'wss://ws.shared.grassrootstexts.com',
    redirectUrl: `https://unlocksurvey.grassrootstexts.com`,
  });

  map.set(Auth0SubDomain.JLOFT, {
    label: 'The J Loft Company',
    clientId: Auth0OrganizationAppID.SHARED,
    organization: Auth0OrganizationName.JLOFT,
    organizationId: Auth0OrganizationID.JLOFT,
    apiUrl: `https://api.shared.grassrootstexts.com`,
    websocketUrl: 'wss://ws.shared.grassrootstexts.com',
    redirectUrl: `https://jloft.grassrootstexts.com`,
  });

  map.set(Auth0SubDomain.TARGETEDVICTORY, {
    label: 'Targeted Victory',
    clientId: Auth0OrganizationAppID.TARGETEDVICTORY,
    organization: Auth0OrganizationName.TARGETEDVICTORY,
    organizationId: Auth0OrganizationID.TARGETEDVICTORY,
    apiUrl: `https://api.targetedvictory.grassrootstexts.com`,
    websocketUrl: 'wss://ws.targetedvictory.grassrootstexts.com',
    redirectUrl: `https://targetedvictory.grassrootstexts.com`,
  });

  map.set(Auth0SubDomain.TAG, {
    label: 'TAG',
    clientId: Auth0OrganizationAppID.TAG,
    organization: Auth0OrganizationName.TAG,
    organizationId: Auth0OrganizationID.TAG,
    apiUrl: `https://api.tag.grassrootstexts.com`,
    websocketUrl: 'wss://ws.tag.grassrootstexts.com',
    redirectUrl: `https://tag.grassrootstexts.com`,
  });

  map.set(Auth0SubDomain.HICKORY, {
    label: 'Hickory Data',
    clientId: Auth0OrganizationAppID.TAG,
    organization: Auth0OrganizationName.HICKORY,
    organizationId: Auth0OrganizationID.HICKORY,
    apiUrl: `https://api.tag.grassrootstexts.com`,
    websocketUrl: 'wss://ws.tag.grassrootstexts.com',
    redirectUrl: `https://hickory.grassrootstexts.com`,
  });

  map.set(Auth0SubDomain.DONORBUREAU, {
    label: 'Donor Bureau',
    clientId: Auth0OrganizationAppID.DONORBUREAU,
    organization: Auth0OrganizationName.DONORBUREAU,
    organizationId: Auth0OrganizationID.DONORBUREAU,
    apiUrl: `https://api.donorbureau.grassrootstexts.com`,
    websocketUrl: 'wss://ws.donorbureau.grassrootstexts.com',
    redirectUrl: `https://donorbureau.grassrootstexts.com`,
  });

  map.set(Auth0SubDomain.DEV, {
    label: 'DEV',
    clientId: Auth0OrganizationAppID.DEV,
    organization: Auth0OrganizationName.DEV,
    organizationId: Auth0OrganizationID.DEV,
    apiUrl: `https://api.dev.grassrootstexts.com`,
    websocketUrl: 'wss://ws.dev.grassrootstexts.com',
    redirectUrl: `https://dev.grassrootstexts.com`,
  });

  map.set(Auth0SubDomain.DEV2, {
    label: 'DEV2',
    clientId: Auth0OrganizationAppID.DEV,
    organization: Auth0OrganizationName.DEV2,
    organizationId: Auth0OrganizationID.DEV2,
    apiUrl: `https://api.dev.grassrootstexts.com`,
    websocketUrl: 'wss://ws.dev.grassrootstexts.com',
    redirectUrl: `https://dev2.grassrootstexts.com`,
  });

  map.set(Auth0SubDomain.STAGING, {
    label: 'STAGING',
    clientId: Auth0OrganizationAppID.STAGING,
    organization: Auth0OrganizationName.STAGING,
    organizationId: Auth0OrganizationID.STAGING,
    apiUrl: `https://api.staging.grassrootstexts.com`,
    websocketUrl: 'wss://ws.staging.grassrootstexts.com',
    redirectUrl: `https://staging.grassrootstexts.com`,
  });

  map.set(Auth0SubDomain.STAGING2, {
    label: 'STAGING2',
    clientId: Auth0OrganizationAppID.STAGING,
    organization: Auth0OrganizationName.STAGING2,
    organizationId: Auth0OrganizationID.STAGING2,
    apiUrl: `https://api.staging.grassrootstexts.com`,
    websocketUrl: 'wss://ws.staging.grassrootstexts.com',
    redirectUrl: `https://staging2.grassrootstexts.com`,
  });


  map.set(Auth0SubDomain.LOCAL, {
    ...(<Auth0Config>map.get(Auth0SubDomain.DEV)),
    apiUrl: `http://localhost:3000/dev`,
    redirectUrl: 'http://localhost:4200',
  });

  return map;
}
