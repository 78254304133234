import {
  IContactListEntity,
  IExportContactListRequest,
  IGetContactListBreakdownResponse,
  IHttpResponse
} from '@gr/shared/models';
import { Transition } from '@headlessui/react';
import { DownloadIcon } from '@heroicons/react/solid';
import useAxios from 'axios-hooks';
import { Fragment, useEffect, useState } from 'react';
import { axiosGet, axiosPost } from '../../authAxios';
import { downloadFileFromUrl } from '../../providers/utility.provider';
import { Button } from '../shared/Buttons/Button';
import { ButtonVariantEnum } from '../shared/Buttons/types';
import { Card } from '../shared/Card';
import { DetailsPanel } from '../shared/DetailsPanel/DetailsPanel';
import { DetailsPanelSize, IButtonOptions } from '../shared/DetailsPanel/types';
import LoadingIndicator from '../shared/LoadingIndicator';
import { Table } from '../shared/Table/Table';
import ContactListForm, { IContactListForm } from './ContactListForm';
import ContactListLeadGeneration from './ContactListLeadGeneration';
import { breakdownColumns, timezoneColumns } from './types';

interface IContactListDetailsPanelProps {
  show: boolean;
  loading: boolean;
  errorMessage?: string;
  selectedItem?: IContactListEntity;
  leftPanelButtonOptions: IButtonOptions[];
  onClosePanel: () => void;
  handleSubmit: (formData: IContactListForm) => Promise<void>;
}

const ContactListDetailsPanel = ({
  show,
  loading,
  errorMessage,
  selectedItem,
  leftPanelButtonOptions,
  onClosePanel,
  handleSubmit,
}: IContactListDetailsPanelProps) => {
  const [collapseBreakdown, setCollapseBreakdown] = useState(false);
  const [collapseTimezone, setCollapseTimezone] = useState(false);
  const [isExporting, setIsExporting] = useState(false);
  const [prevStillUploading, setPrevStillUploading] = useState(false);

  const [{ data: breakdownData, loading: breakdownLoading }, getBreakdownData] = useAxios<
    IHttpResponse<IGetContactListBreakdownResponse[]>
  >(
    {
      url: '/contact-lists-get-breakdown',
      method: 'POST',
      data: { contactListId: selectedItem?.id },
    },
    { manual: true }
  );

  const [{ data: timezoneData, loading: timezoneLoading }, getTimezoneBreakdown] = useAxios<
    IHttpResponse<{ timezones: any[]; }>
  >(
    {
      url: '/contact-lists-get-timezones',
      method: 'POST',
      data: { contactListId: selectedItem?.id },
    },
    { manual: true }
  );

  const breakdownInfo = breakdownData?.data.map((item) => ({ type: item.type ?? 'Unknown', count: item.count })) ?? [];
  const totalContactCount = breakdownInfo?.reduce((sum, br) => sum + br.count, 0) ?? 0;

  const timezoneBreakdown = timezoneData?.data.timezones ?? [];

  const populateBreakdown = async () => {
    try {
      await Promise.all([getTimezoneBreakdown(), getBreakdownData()]);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    if (selectedItem) {
      retrieveLoadingStatus();
      setCollapseBreakdown(false);
      setCollapseTimezone(false);
      populateBreakdown();
    }
  }, [selectedItem]);

  const retrieveLoadingStatus = async () => {
    const currList = (await axiosGet(`/v1/contact-lists/${selectedItem!.id}`)).data.data;
    setPrevStillUploading(currList?.isUploading);
  };

  const exportContactList = async () => {
    if (selectedItem) {
      try {
        const request: IExportContactListRequest = {
          contactListId: selectedItem.id,
          contactListName: selectedItem.name,
        };

        setIsExporting(true);

        const {
          data: { data: signedUrl },
        } = await axiosPost<IHttpResponse<string>>('/contact-lists-export', request);

        downloadFileFromUrl(signedUrl);
      } catch (err) {
        console.error(err);
      } finally {
        setIsExporting(false);
      }
    }
  };

  const closeThisPanel = () => {
    onClosePanel();
    setPrevStillUploading(false);
  };

  return (
    <DetailsPanel
      item={selectedItem}
      title={selectedItem ? 'Edit Contact List' : 'Add Contact List'}
      formId="contact-form"
      size={DetailsPanelSize.Medium}
      show={show}
      isDisabled={prevStillUploading}
      leftPanelButtonOptions={leftPanelButtonOptions}
      closePanel={closeThisPanel}
      errorMessage={errorMessage}
      loading={loading}
    >
      <>
        {prevStillUploading && (
          <span className="mb-6 text-red-700">
            We are still processing the previous upload. Please hold-off any updates for a few minutes.
          </span>
        )}

        <ContactListForm item={selectedItem} breakdownInfo={breakdownInfo} onSubmit={handleSubmit} />
        {selectedItem && (
          <div className="mt-2 space-y-4">
            {/* TODO: Make this into a custom component */}
            <Card>
              <div
                onClick={() => {
                  totalContactCount > 0 && setCollapseBreakdown(!collapseBreakdown);
                }}
              >
                <h3
                  className={`text-lg font-medium leading-6   ${totalContactCount > 0 && !breakdownLoading && 'text-sky-600 hover:underline hover:cursor-pointer'
                    }`}
                >
                  Contact List Breakdown
                  {breakdownLoading && <LoadingIndicator />}
                </h3>
                {!breakdownLoading && (
                  <p className="max-w-2xl mt-1 text-sm text-gray-500">
                    {totalContactCount === 0 ? 'No contacts' : `${totalContactCount.toLocaleString()} total contacts`}
                  </p>
                )}
              </div>
              <Transition
                show={collapseBreakdown}
                as={Fragment}
                enter="ease-in-out duration-200"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in-out duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <div className="border-t border-gray-200">
                  <Table columns={breakdownColumns} items={breakdownInfo} shimmer={false} height={'20vh'} />
                </div>
              </Transition>
            </Card>

            <Card>
              <div
                onClick={() => {
                  timezoneBreakdown?.length > 0 && setCollapseTimezone(!collapseTimezone);
                }}
              >
                <h3
                  className={`text-lg font-medium leading-6   ${timezoneBreakdown?.length > 0 &&
                    !timezoneLoading &&
                    'text-sky-600 hover:underline hover:cursor-pointer'
                    }`}
                >
                  Timezone Breakdown
                  {timezoneLoading && <LoadingIndicator />}
                </h3>
                {!timezoneLoading && (
                  <p className="max-w-2xl mt-1 text-sm text-gray-500">
                    {!breakdownLoading && totalContactCount === 0
                      ? 'No contacts'
                      : `${timezoneBreakdown?.length} timezones`}
                  </p>
                )}
              </div>
              <Transition
                show={collapseTimezone}
                as={Fragment}
                enter="ease-in-out duration-200"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in-out duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <div className="border-t border-gray-200">
                  <Table columns={timezoneColumns} items={timezoneBreakdown} shimmer={false} height={'20vh'} />
                </div>
              </Transition>
            </Card>

            <ContactListLeadGeneration selectedItem={selectedItem} totalContactCount={totalContactCount} />

            <div className="flex justify-end">
              <Button
                title="Export Contacts from List"
                variant={ButtonVariantEnum.SECONDARY}
                onClick={exportContactList}
                leadingIcon={isExporting ? undefined : <DownloadIcon className="w-4 h-4" />}
              >
                {isExporting ? <LoadingIndicator position="CENTER" /> : 'Export'}
              </Button>
            </div>
          </div>
        )}
      </>
    </DetailsPanel>
  );
};

export default ContactListDetailsPanel;
