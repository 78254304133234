import { Button, ButtonVariantEnum, EmailIcon, Info, TableList, TextInput } from '@Wonder-Cave/ui';
import { IDropdownValue } from '@Wonder-Cave/ui/dist/Autocomplete';
import { Filter } from '@Wonder-Cave/ui/dist/Table/Filters';
import useRoles from '@gr/portal/hooks/useRoles';
import { convertEnumToReadableString } from '@gr/portal/providers/utility.provider';
import {
  Auth0Role,
  Auth0User,
  FilterDataTypeEnum,
  FilterOperatorEnum,
  ISearchFilter,
  ISearchRequest
} from '@gr/shared/models';
import { isStaffUserOrHigher } from '@gr/shared/utils';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import useUsers from '../../hooks/useUsers';
import ClientDropdown from '../shared/Form/Dropdowns/NewClientDropdown';
import UploadUsersModal from './UploadUsersModal';
import { defaultUsersTableOptions, getUsersColumns } from './types';

const Users = () => {
  const [searchText, setSearchText] = useState('');
  const [showUpload, setShowUpload] = useState(false);
  const [tableOptions, setTableOptions] = useState(defaultUsersTableOptions);
  const history = useHistory();
  const isSuperAdmin = useRoles([Auth0Role.GR_ADMIN, Auth0Role.SUPER_ADMIN]);
  const [{ data: userData, loading: userLoading, error: userError }, searchUsers] = useUsers({
    ...tableOptions,
    filters: (isSuperAdmin || tableOptions.filters.some(f => f.fieldName === 'role') ? [...tableOptions.filters] : [{
      fieldName: 'role',
      operator: FilterOperatorEnum.IN,
      dataType: FilterDataTypeEnum.STRING,
      value: tableOptions.filters.some(f => f.fieldName === 'clients') ? Object.values(Auth0Role).filter(role => ![Auth0Role.GR_ADMIN, Auth0Role.SUPER_ADMIN, Auth0Role.STAFF, Auth0Role.ADMIN].includes(role)) : Object.values(Auth0Role).filter(role => ![Auth0Role.GR_ADMIN, Auth0Role.SUPER_ADMIN, Auth0Role.STAFF, Auth0Role.ADMIN].includes(role))
    }, ...tableOptions.filters]),
  });

  useEffect(() => {
    const searchText = tableOptions.filters.find((filter) => filter.fieldName === 'email')?.value;

    handleRefetch();

    setSearchText(searchText ?? '');
  }, [tableOptions]);

  const usersTotalCount = userData?.data.totalCount;

  const columns = getUsersColumns(
    { name: (item: Auth0User) => history.push(`users/${item?.user_id}`) },
    {
      name: (item: Auth0User) => <div>
        <h5 className='mb-2 text-medium-gray'>{item.role?.replaceAll('_', ' ')}</h5>
        <div className='hover:text-wc-blue hover:underline'>{(item.given_name ?? '') + ' ' + (item.family_name ?? '')}</div>
        <div className="flex items-center body-text-small text-medium-gray"><EmailIcon /><div className='ml-2'>{item.email}</div></div>
      </div>,
      // blocked: (item: Auth0User) => <ToggleSwitch checkedColor='bg-grass' checked={!item.blocked} onChange={() => { }} />,
      clients: (item: Auth0User) => {
        const clients = isStaffUserOrHigher(item?.role) ? [{ name: 'All Clients ' }] : item?.clients?.length > 0 ? item?.clients : [{ name: '-' }];
        return <div className='flex items-center whitespace-break-spaces'>{clients.slice(0, 3).map(c => c.name).join(', ')} <Info text={clients.slice(3).map(c => c.name).join(', ')} infoElement={<div className='ml-2 underline cursor-pointer body-text-bold'>{clients.length > 3 ? `+${clients.length - 3}` : ''}</div>} /></div>;
      },
      createdAt: (item: Auth0User) => moment(item.created_at).format('L'),
      requestedBy: (item: Auth0User) => <div className='pr-2'>{item.app_metadata.requestedBy}</div>
    }
  );

  const users = userData?.data.records.map((user) => ({
    ...user,
    role: user?.app_metadata?.memberships?.[0]?.roles?.[0],
  }));

  const handleRefetch = async () => {
    try {
      await searchUsers();
    } catch (error) { }
  };

  const handleSearchOptionChange = (searchOptions: ISearchRequest) => {
    setTableOptions(searchOptions);
  };

  const handleSearch = (overrideText?: string) => {
    let emailFilter: ISearchFilter = {
      dataType: FilterDataTypeEnum.STRING,
      fieldName: 'email',
      operator: FilterOperatorEnum.CONTAINS,
      value: searchText,
    };

    if (overrideText !== null && overrideText !== undefined) {
      setSearchText(overrideText);
      emailFilter.value = overrideText;
    }

    const options = { ...defaultUsersTableOptions };
    const filters = tableOptions.filters.filter((filter) => filter.fieldName !== 'email');
    options.filters = emailFilter.value ? [...filters, emailFilter] : [...filters];

    setTableOptions(options);
  };

  return (
    <div>
      <div className="flex items-center mb-16 px-28">
        <h1 className='mr-4'>Users</h1>
        <div className='w-2/6 ml-auto mr-4'>
          <TextInput
            id="usersEmailSearch"
            name="usersEmailSearch"
            placeholder="Email"
            search
            value={searchText}
            onChange={(e) => {
              if (
                e.target.value.length < 1 &&
                tableOptions.filters.find((x) => x.fieldName === 'email')?.value?.length > 0
              ) {
                setTableOptions((prevState) => {
                  const filteredPrevStateFilters = prevState.filters.filter((filter) => filter.fieldName !== 'email');
                  filteredPrevStateFilters.push({
                    fieldName: 'email',
                    dataType: FilterDataTypeEnum.STRING,
                    operator: FilterOperatorEnum.CONTAINS,
                    value: '',
                  });
                  prevState.filters = filteredPrevStateFilters;
                  return {
                    ...prevState,
                    filters: [
                      ...prevState.filters
                    ],
                  };
                });
              }
              setSearchText(e.target.value);
            }}
            onEnter={(e) => {
              setTableOptions((prevState) => {
                // remove old search text from prev state
                const filteredPrevStateFilters = prevState.filters.filter((filter) => filter.fieldName !== 'email');
                prevState.filters = filteredPrevStateFilters;
                return {
                  ...prevState,
                  filters: [...prevState.filters,
                  ... (searchText.length > 0 ? [
                    {
                      fieldName: 'email',
                      dataType: FilterDataTypeEnum.STRING,
                      operator: FilterOperatorEnum.CONTAINS,
                      value: searchText,
                    },
                  ] : [])],
                };
              });
            }}
          />
        </div>
        <div className="flex space-x-4">
          <Button
            id="users-upload"
            variant={ButtonVariantEnum.PRIMARY}
            onClick={() => setShowUpload(true)}
          >
            UPLOAD USERS
          </Button>

          <Button variant={ButtonVariantEnum.TERTIARY} className="self-end" onClick={() => history.push('users/new')}>
            INVITE USER
          </Button>
          {/* <Button variant={ButtonVariantEnum.SECONDARY}>
            Export Data
          </Button> */}
        </div>
      </div>

      <TableList
        columns={columns}
        items={users ?? []}
        shimmer
        loading={userLoading}
        limit={50}
        totalCount={usersTotalCount ?? 0}
        filters={[{
          label: 'Client',
          id: 'clients',
          dataType: FilterDataTypeEnum.STRING,
          operator: FilterOperatorEnum.EQUALS,
          customDropdown: (value, onChange) => {
            return <ClientDropdown
              placeholder='Search for a client'
              multiple={false}
              gray={true}
              noLabel={true}
              value={value}
              onChange={(newValue) => {
                onChange(newValue as IDropdownValue);
              }}
              renderError={false}
            />;
          }
        }, {
          label: 'Role',
          id: 'role',
          dataType: FilterDataTypeEnum.STRING,
          operator: FilterOperatorEnum.EQUALS,
          options: Object.entries(Auth0Role).filter(([_, role]) => {
            return !(!isSuperAdmin && [Auth0Role.SUPER_ADMIN, Auth0Role.ADMIN, Auth0Role.STAFF, Auth0Role.GR_ADMIN].includes(role));
          }).map(([key, value]) => {
            return {
              label: convertEnumToReadableString(value),
              value: value
            };
          }),
          optionsLabel: 'Select Role',
        }]}
        onFilter={(filters: Filter[]) => {
          if (filters.length > 0) {
            const formattedFilters: ISearchFilter[] = filters.map(f => {
              return [{
                fieldName: f.id,
                dataType: f.dataType as FilterDataTypeEnum,
                operator: f.operator,
                value: f.selectedOption?.value
              }];
            }).flat();
            const searchTextFilter = tableOptions.filters.find((option) => option.fieldName === 'email');
            if (searchTextFilter) formattedFilters.push(searchTextFilter);
            setTableOptions((prevState) => ({
              ...prevState,
              filters: [
                ...formattedFilters
              ]
            }));
          } else {
            const searchTextFilter = tableOptions.filters.find((option) => option.fieldName === 'email');
            let updatedTableOptions = defaultUsersTableOptions;
            if (searchTextFilter) updatedTableOptions = { ...updatedTableOptions, filters: [searchTextFilter] };
            setTableOptions(updatedTableOptions);
          }
        }}
      />

      {/* <Table
        columns={columns}
        items={users}
        tableSearchOptions={tableOptions}
        onSearchOptionChange={handleSearchOptionChange}
        filter
        shimmer
        paginate
        sort={true}
        count={usersTotalCount}
        loading={userLoading}
        booleanLabels={[{ fieldName: 'active', displayValues: { trueLabel: 'Active', falseLabel: 'Inactive' } }]}
        filterColumns={filterColumns}
        customFilters={customFilters}
        filterDropdownOptions={usersFilterDropdownOptions}
      /> */}
      <UploadUsersModal show={showUpload} setShow={setShowUpload} onUpload={handleRefetch} />
    </div>
  );
};

export default Users;
