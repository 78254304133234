import { useAuth0 } from '@auth0/auth0-react';
import { Auth0Role, Auth0User, Auth0UserMembership } from '@gr/shared/models';
import { getAuth0Organization } from '@gr/shared/utils/auth0-sub-domain.service';
import { Redirect } from 'react-router-dom';
import { Button } from './shared/Buttons/Button';
import { ButtonVariantEnum } from './shared/Buttons/types';
import LoadingIndicator from './shared/LoadingIndicator';

interface ILoginProps {
  authenticate: () => void;
  authenticateWithConsent: () => void;
  isConfigured: boolean;
}

const Login = ({ authenticate, authenticateWithConsent, isConfigured }: ILoginProps): JSX.Element => {
  const { isAuthenticated: isLoggedIn, isLoading: isAuth0Loading, loginWithRedirect, user: auth0User } = useAuth0();

  const outboxRoles = [Auth0Role.GR_CLICKER, Auth0Role.STAFF];
  const twoWayRoles = [Auth0Role.GR_CLIENT_MANAGER, Auth0Role.GR_CLIENT_USER];
  const campaignRoles = [
    Auth0Role.ADMIN,
    Auth0Role.SUPER_ADMIN,
    Auth0Role.GR_ADMIN,
    Auth0Role.GR_STAFF_MANAGER,
    Auth0Role.GR_STAFF_USER,
  ];

  const getPath = (user?: Auth0User): string => {
    const memberships: Auth0UserMembership[] = user?.app_metadata?.memberships ?? [];
    const membership = memberships.find((membership) => membership?.organization === getAuth0Organization());
    const roles = membership?.roles ?? [];

    if (hasAnyRole(roles, outboxRoles)) {
      return '/app/my-campaigns';
    }

    if (hasAnyRole(roles, twoWayRoles)) {
      return '/app/conversations';
    }

    if (hasAnyRole(roles, campaignRoles)) {
      return '/app/campaigns';
    }

    return '/app/dashboard';
  };

  const hasAnyRole = (userRoles: Auth0Role[], targetRoles: Auth0Role[]) => {
    return userRoles?.some((role) => targetRoles?.includes(role));
  };

  if (isAuth0Loading) {
    console.log('Auth0: Loading');
    return <LoadingIndicator size={16} position="CENTER" vPosition="CENTER" />;
  }

  if (!isLoggedIn) {
    loginWithRedirect();
    return <></>;
  }

  if (isLoggedIn && !isConfigured) {
    console.log('process.env.NX_LOCAL', process.env.NX_LOCAL);
    if (process.env.NX_LOCAL?.toLowerCase() === 'true') {
      // For security reasons, localhost needs to request consent
      // This can only be done via a click event
      // https://auth0.com/docs/get-started/applications/confidential-and-public-applications/user-consent-and-third-party-applications
      return (
        <div className="flex bg-sky-800 w-screen h-screen text-white justify-center items-center">
          <Button
            variant={ButtonVariantEnum.SECONDARY}
            onClick={() => {
              authenticateWithConsent();
            }}
          >
            Consent (localhost only)
          </Button>
        </div>
      );
    }
  }

  return <Redirect to={{ pathname: getPath(auth0User as any) }} />;
};

export default Login;
