import {
  Auth0User,
  IHttpSearchResponse,
  ISearchRequest
} from '@gr/shared/models';
import useAxios, { UseAxiosResult } from 'axios-hooks';

const useUsers = (searchOptions: ISearchRequest): UseAxiosResult<IHttpSearchResponse<Auth0User>> => {
  return useAxios<IHttpSearchResponse<Auth0User>>({
    url: 'auth0-get-users',
    method: 'POST',
    data: searchOptions,
  });
};

export default useUsers;
