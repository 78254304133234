import { GetUIStatusRequest, mapToUiStatus } from '../services/campaigns.service';

interface ICampaignStatusProps extends GetUIStatusRequest {
}

const CampaignStatus = (props: ICampaignStatusProps) => {
  return <>{mapToUiStatus(props)}</>;
};

export default CampaignStatus;
