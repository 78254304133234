import { CampaignTypeEnum } from '@gr/shared/enums';
import {
  Auth0Role,
  FilterDataTypeEnum,
  FilterOperatorEnum,
  IClientEntity,
  IClientView,
  ICreateClientRequest,
  IHttpResponse,
  IProvidersChangeForTypeRequest,
  ISearchPagination,
  ISearchRequest,
  ISetDefaultClickerGroupRequest,
  IUpdateClientRequest,
} from '@gr/shared/models';
import { SearchIcon } from '@heroicons/react/outline';
import { format } from 'date-fns';
import { useEffect, useState } from 'react';
import { axiosPost } from '../../authAxios';
import { NotificationType, useNotifications } from '../../contexts/NotificationContext';
import useClients from '../../hooks/useClients';
import useRoles from '../../hooks/useRoles';
import { Button } from '../shared/Buttons/Button';
import { CircularButton } from '../shared/Buttons/CircularButton';
import { ButtonVariantEnum } from '../shared/Buttons/types';
import { Card } from '../shared/Card';
import { TextInput } from '../shared/Form/TextInput';
import { Pagination } from '../shared/Table/Pagination';
import { Table } from '../shared/Table/Table';
import ClientDetailsPanel from './ClientDetailsPanel';
import { IClientForm } from './ClientForm';
import { getColumns, noAllClientsClientTableOptions } from './types';

const Clients = () => {
  const [clientLoading, setClientLoading] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [selectedItem, setSelectedItem] = useState<IClientView>();
  const [showDetailsPanel, setShowDetailsPanel] = useState(false);
  const [tableOptions, setTableOptions] = useState(noAllClientsClientTableOptions);
  const { addNotification } = useNotifications();
  const allowProviderUpdated = useRoles([Auth0Role.GR_ADMIN, Auth0Role.SUPER_ADMIN]);
  const allowDefaultClickerGroup = useRoles([Auth0Role.ADMIN, Auth0Role.GR_STAFF_USER]);
  const allowClientEdit = useRoles([Auth0Role.ADMIN, Auth0Role.GR_STAFF_USER]);

  const [{ data, loading, error }, refetch] = useClients(tableOptions);
  // Create client hook
  // Update client hook

  const clients = data?.data.records;
  const totalCount = data?.data.totalCount;

  const columns = getColumns(
    {
      name: (item: IClientView) => {
        openDetailsPanel(item);
      },
    },
    allowProviderUpdated
  );

  const handleSearchOptionChange = (searchOptions: ISearchRequest) => {
    setTableOptions(searchOptions);
  };

  const handleRefetch = async () => {
    try {
      await refetch();
    } catch (err) { }
  };

  useEffect(() => {
    const searchText = tableOptions.filters.find((filter) => filter.fieldName === 'name')?.value;

    handleRefetch();

    setSearchText(searchText ?? '');
  }, [tableOptions]);

  const handleSearch = () => {
    let searchFilter = {
      dataType: FilterDataTypeEnum.STRING,
      fieldName: 'name',
      operator: FilterOperatorEnum.CONTAINS,
      value: searchText,
    };

    const newSearchOptions = { ...tableOptions };

    newSearchOptions.filters = newSearchOptions.filters.filter((filter) => filter.fieldName !== 'name');

    newSearchOptions.filters.push(searchFilter);

    newSearchOptions.pagination = noAllClientsClientTableOptions.pagination;

    setTableOptions(newSearchOptions);
  };

  const handleClear = () => {
    const newSearchOptions = { ...tableOptions };

    newSearchOptions.filters = newSearchOptions.filters.filter((filter) => filter.fieldName !== 'name');

    setTableOptions(newSearchOptions);
  };

  const openDetailsPanel = async (item?: IClientView) => {
    setSelectedItem(item);
    setShowDetailsPanel(true);
  };

  const closeDetailsPanel = () => {
    setShowDetailsPanel(false);
    setSelectedItem(undefined);
  };

  const createClient = async (formData: IClientForm): Promise<void> => {
    const request: ICreateClientRequest = {
      name: formData.name,
      externalId: formData.externalId,
      isActive: formData.isActive!.value,
      senderIdentifier: formData.senderIdentifier ?? '',
      tcrBrandId: formData.tcrBrandId ?? '',
      messageFlow: formData.messageFlow ?? '',
      twoWayEnabled: formData.twoWayEnabled ?? false,
    };

    const response = await axiosPost<IHttpResponse<IClientEntity>>('/clients-create', request);

    if (response?.status === 200) {
      const client = response?.data?.data ?? undefined;
      const clickerGroupId = formData?.defaultClickerGroupId ?? undefined;
      await setDefaultClickerGroup(client, clickerGroupId);
    }
  };

  const updateClient = async (formData: IClientForm): Promise<void> => {
    const request: IUpdateClientRequest = {
      id: selectedItem?.id!,
      name: formData.name,
      // providerId: formData.providerId ?? '',
      senderIdentifier: formData.senderIdentifier ?? '',
      tcrBrandId: formData.tcrBrandId ?? '',
      messageFlow: formData.messageFlow ?? '',
      externalId: formData.externalId,
      isActive: formData.isActive!.value,
      twoWayEnabled: formData.twoWayEnabled ?? false,
    };

    await axiosPost<IHttpResponse<IClientEntity>>('/clients-update', request);
    // Update the campaign type/provider/client relations
    if (formData.providers?.length ?? 0 > 0) {
      const promises: any[] = [];
      for (let i = 0; i < formData.providers!.length; i++) {
        if (!!formData.providers && !!formData.providers[i]) {
          const request: IProvidersChangeForTypeRequest = {
            clientId: selectedItem?.id!,
            providerId: formData.providers![i].provider ?? '',
            campaignType: CampaignTypeEnum[formData.providers![i].campaignType ?? 'FUNDRAISING'],
          };
          promises.push(
            axiosPost<IHttpResponse<IProvidersChangeForTypeRequest>>('/providers-change-for-type', request)
          );
        }
      }
      await Promise.allSettled(promises);
    }

    const clickerGroupId = formData?.defaultClickerGroupId ?? undefined;
    await setDefaultClickerGroup(selectedItem, clickerGroupId);
  };

  const saveClient = async (formData: IClientForm): Promise<void> => {
    setClientLoading(true);
    try {
      if (selectedItem) {
        await updateClient(formData);
      } else {
        await createClient(formData);
      }
    } catch (err) {
      console.error(err);
      // TODO: Add an error banner on panels
      addNotification({
        header: 'Error',
        content: 'An unexpected error occurred when attempting to save the Client.',
        type: NotificationType.FAILURE,
      });
      return;
    } finally {
      setClientLoading(false);
    }

    closeDetailsPanel();

    handleRefetch();

    addNotification({
      header: 'Client saved successfully!',
    });
  };

  const handlePagination = (newPaginationConfig: ISearchPagination) => {
    const newSearchOptions = { ...tableOptions };
    newSearchOptions.pagination = newPaginationConfig;

    handleSearchOptionChange(newSearchOptions);
  };

  const setDefaultClickerGroup = async (client?: IClientEntity | IClientView, clickerGroupId?: string) => {
    const defaultClickerGroupId = (clickerGroupId?.length ?? 0) > 0 ? clickerGroupId : null;

    if (allowDefaultClickerGroup && client?.id) {
      const request: ISetDefaultClickerGroupRequest = {
        clientId: client.id,
        defaultClickerGroupId,
      };

      await axiosPost<IHttpResponse<void>>('/clicker-groups-set-default', request);
    }
  };

  return (
    <>
      <h2 id="clients-title" className="flex justify-between pb-2 dark:text-white">
        Clients
        <div className="flex sm:hidden">
          <CircularButton onClick={() => openDetailsPanel()} />
        </div>
      </h2>
      <div className="flex pb-2">
        <div className="flex">
          <TextInput
            id="nameLookup"
            name="nameLookup"
            placeholder="Name"
            value={searchText}
            onChange={(e) => {
              setSearchText(e.target.value);
            }}
            onClear={handleClear}
            onEnter={handleSearch}
            leadingIcon={<SearchIcon />}
            clearable
          />
          <Button
            id="client-search"
            variant={ButtonVariantEnum.SECONDARY}
            className="self-end ml-2"
            onClick={() => {
              handleSearch();
            }}
          >
            Search
          </Button>
        </div>

        <div className="hidden ml-auto sm:flex">
          <Button
            id="add-client"
            variant={ButtonVariantEnum.SECONDARY}
            disabled={!allowClientEdit}
            onClick={() => {
              openDetailsPanel();
            }}
          >
            Add Client
          </Button>
        </div>
      </div>

      <div className="hidden md:flex">
        <Table
          shimmer
          loading={loading}
          columns={columns}
          items={clients}
          count={totalCount}
          tableSearchOptions={tableOptions}
          onSearchOptionChange={handleSearchOptionChange}
          booleanLabels={[{ fieldName: 'isActive', displayValues: { trueLabel: 'Enabled', falseLabel: 'Disabled' } }]}
          sort
          paginate
        />
      </div>

      <ul role="list" className="flex flex-col mt-4 space-y-4 md:hidden">
        {clients?.map((client) => (
          <Card
            key={client.id}
            header={client.name}
            action={() => openDetailsPanel(client)}
            actionOnHeaderClick
            padded={false}
          >
            <div className="flex-1 px-3 py-3 space-y-4 truncate">
              <div className="flex flex-row justify-between mt-1 text-sm text-gray-500">
                <div className="flex flex-col">
                  <label className="font-semibold">Status: </label>
                  {allowProviderUpdated && <label className="font-semibold">Provider Updated: </label>}
                  <label className="font-semibold">Created Date: </label>
                  <label className="font-semibold">Active Carrier Exclusion: </label>
                </div>
                <div className="flex flex-col items-end">
                  <span>{client.isActive ? 'Enabled' : 'Disabled'}</span>
                  <span>{format(new Date(`${client.providerUpdatedAt}`), 'MM/dd h:mm a')}</span>
                  <span>{format(new Date(`${client.createdAt}`), 'MM/dd h:mm a')}</span>
                  <span>{client.hasExclusion ? 'Yes' : 'No'}</span>
                </div>
              </div>
            </div>
          </Card>
        ))}
        {/* Pagination */}
        <Pagination
          count={totalCount || 0}
          loading={loading}
          paginationConfig={tableOptions.pagination}
          onPaginate={handlePagination}
        />
      </ul>

      <ClientDetailsPanel
        show={showDetailsPanel}
        loading={clientLoading}
        onClosePanel={closeDetailsPanel}
        selectedItem={selectedItem}
        handleSubmit={saveClient}
        isReadOnly={!allowClientEdit}
      />
    </>
  );
};

export default Clients;
