import { useAuth0 } from '@auth0/auth0-react';
import { Auth0MembershipWithMeta, Auth0Role, Auth0UserMembership, IHttpResponse, ISendingWindow } from '@gr/shared/models';
import { secondsToMilliseconds } from 'date-fns';
import { isNil } from 'lodash';
import { useEffect, useState } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { axiosGet, axiosPost } from './authAxios';
import UpsertUser from './components/AdminSettings/UpsertUser';
import Users from './components/AdminSettings/Users';
import ViewUser from './components/AdminSettings/ViewUser';
import { CampaignDetails } from './components/Campaigns/CampaignDetails';
import Campaigns from './components/Campaigns/Campaigns';
import CreateCampaign from './components/Campaigns/CreateCampaign';
import ExecuteCampaign from './components/Campaigns/MyCampaigns/ExecuteCampaign';
import MyCampaigns from './components/Campaigns/MyCampaigns/MyCampaigns';
import CarrierExclusions from './components/CarrierExclusions/CarrierExclusions';
import Clients from './components/Clients/Clients';
import { Conversations as ClientConversations } from './components/Clients/Conversations';
import ContactLists from './components/Contacts/ContactLists';
import { Conversations } from './components/Conversations/Conversations';
import HealthCheckNumbers from './components/HealthCheckNumbers/HealthCheckNumbers';
import Links from './components/LinkOrders/LinkOrders';
import NotificationsRegion from './components/NotificationsRegion';
import OptOuts from './components/OptOuts/OptOuts';
import SystemNumbers from './components/SystemNumbers/SystemNumbers';
import TCRCampaigns from './components/TCRCampaigns/TCRCampaigns';
import TestMessageData from './components/TestMessageData/TestMessageData';
import { PageNotFound } from './components/shared/Auth/PageNotFound';
import { ProtectedRoute } from './components/shared/Auth/ProtectedRoute';
import { LeftNav } from './components/shared/LeftNav/LeftNav';
import LoadingIndicator from './components/shared/LoadingIndicator';
import { CacheContextProvider, fallbackSendingWindow, ICacheContext } from './contexts/CacheContext';
import { MembershipContextProvider } from './contexts/MembershipContext';
import { NotificationsProvider } from './contexts/NotificationContext';
import { SidebarContextProvider } from './contexts/SidebarContext';
import { SocketContextProvider, socketManager } from './contexts/SocketContext';
import { logout } from './providers/auth0.service';

interface IAppProps {
  configured: boolean;
}

const AppWrapper = ({ configured }: IAppProps) => {
  const [sidebarOpen, setSidebarOpen] = useState(window.innerWidth >= 768);
  const [activeUser, setActiveUser] = useState({});
  const [devMode, setDevMode] = useState(false);
  const [membership, setMembership] = useState<Auth0UserMembership>();
  const sidebarContextValue = { devMode, sidebarOpen, activeUser, setDevMode, setSidebarOpen, setActiveUser };
  const { isLoading, isAuthenticated: isLoggedIn, logout: auth0Logout, user: auth0User } = useAuth0();
  const [permissionsLoaded, setPermissionsLoaded] = useState(false);
  const membershipsContextValue = { membership, isLoaded: permissionsLoaded, setMembership };

  const [isSendingWindowRetrieved, setIsSendingWindowRetrieved] = useState<boolean>(false);
  const [sendingWindow, setSendingWindow] = useState<ISendingWindow>(fallbackSendingWindow);
  const cacheContext: ICacheContext = { sendingWindow, setSendingWindow };

  const staffPageRoles = [Auth0Role.GR_CLICKER];
  const clientPageRoles = [Auth0Role.GR_CLIENT_USER];
  const adminPageRoles = [Auth0Role.GR_STAFF_USER];
  const superAdminPageRoles = [Auth0Role.GR_ADMIN];

  const theme = localStorage.getItem('grassroots-theme');
  if (theme === 'dark') {
    // This enables dark mode if the user has it set as their OS default || (!theme && window.matchMedia('(prefers-color-scheme: dark)').matches)) {
    document.documentElement.classList.add('dark');
  } else {
    document.documentElement.classList.remove('dark');
  }

  useEffect(() => {
    if (configured && isLoggedIn) {
      // Set permissions context
      const getPermissions = async () => {
        try {
          const response = await axiosPost<IHttpResponse<Auth0MembershipWithMeta>>('/auth0-get-my-membership');
          if (response.data.data) {
            setMembership(response.data.data);
            setPermissionsLoaded(true);
          }
        } catch (e) {
          console.error(e);
          logout({ auth0Logout, auth0User });
        }
      };
      getPermissions();
    }
  }, [configured]);

  useEffect(() => {
    if (configured && isLoggedIn) {
      const getSendingWindow = async () => {
        if (isSendingWindowRetrieved) {
          return;
        }

        try {
          const response = await axiosGet<ISendingWindow>('/sending-window');
          setSendingWindow(response?.data ?? fallbackSendingWindow);
          setIsSendingWindowRetrieved(true);
        } catch (error) {
          console.error(error);
          setSendingWindow(fallbackSendingWindow);
        }
      };

      if (isNil(sendingWindow)) {
        getSendingWindow();
      }

      const interval = setInterval(() => {
        getSendingWindow();
      }, secondsToMilliseconds(15));

      return () => clearInterval(interval);
    };
  }, [configured, isLoggedIn, sendingWindow]);

  if (!isLoading && !isLoggedIn) {
    return (
      <Redirect
        to={{
          pathname: '/login',
        }}
      />
    );
  }

  if (!configured || isLoading) {
    console.log('Loading...');
    console.log(`Configured: ${configured}`);
    console.log(`Auth0 Loading: ${isLoading}`);
    return <LoadingIndicator size={16} position="CENTER" vPosition="CENTER" />;
  }

  return (
    <CacheContextProvider value={cacheContext}>
      <MembershipContextProvider value={membershipsContextValue}>
        <SocketContextProvider value={socketManager}>
          <SidebarContextProvider value={sidebarContextValue}>
            <NotificationsProvider>
              <div
                className={`flex h-screen overflow-hidden bg-gray-100 text-black dark:bg-slate-900 dark:text-slate-400`}
              >
                <LeftNav />
                <div className="flex flex-col flex-1 w-0 overflow-hidden">
                  {/* <AppHeader /> */}
                  <NotificationsRegion />
                  <main className={`relative flex-1 pt-6 overflow-y-auto focus:outline-none `}>
                    <div className="h-full px-4">
                      <Switch>
                        {/* <ProtectedRoute path="/app/dashboard" exact component={} /> */}
                        {/* <Redirect from="/app/dashboard" to="/app/my-campaigns" /> */}

                        <ProtectedRoute
                          roles={staffPageRoles}
                          exact
                          path="/app/my-campaigns/:campaignId"
                          component={ExecuteCampaign}
                        />
                        <ProtectedRoute roles={staffPageRoles} exact path="/app/my-campaigns" component={MyCampaigns} />
                        {/* Admin Pages */}
                        <ProtectedRoute roles={adminPageRoles} exact path="/app/contact-lists" component={ContactLists} />
                        <ProtectedRoute
                          roles={[Auth0Role.GR_CLIENT_MANAGER]}
                          exact
                          path="/app/clients"
                          component={Clients}
                        />
                        <ProtectedRoute
                          roles={[Auth0Role.GR_CLIENT_MANAGER]}
                          path="/app/clients/:clientId/conversations"
                          exact
                          component={ClientConversations}
                        />
                        <ProtectedRoute roles={adminPageRoles} exact path="/app/opt-outs" component={OptOuts} />
                        <ProtectedRoute
                          roles={adminPageRoles}
                          exact
                          path="/app/campaigns/create"
                          component={CreateCampaign}
                        />
                        <ProtectedRoute
                          roles={adminPageRoles}
                          exact
                          path="/app/campaigns/:id/edit"
                          component={CreateCampaign}
                        />
                        <ProtectedRoute
                          roles={adminPageRoles}
                          exact
                          path="/app/campaigns/:id"
                          component={CampaignDetails}
                        />
                        <ProtectedRoute roles={adminPageRoles} exact path="/app/campaigns" component={Campaigns} />
                        <ProtectedRoute
                          roles={clientPageRoles}
                          exact
                          path="/app/conversations"
                          component={Conversations}
                        />
                        <ProtectedRoute
                          roles={adminPageRoles}
                          exact
                          path="/app/test-numbers"
                          component={HealthCheckNumbers}
                        />
                        <ProtectedRoute
                          roles={adminPageRoles}
                          exact
                          path="/app/test-message-data"
                          component={TestMessageData}
                        />
                        <ProtectedRoute roles={adminPageRoles} exact path="/app/links" component={Links} />
                        {/* Super Admin Controls */}
                        <ProtectedRoute
                          roles={superAdminPageRoles}
                          exact
                          path="/app/system-numbers"
                          component={SystemNumbers}
                        />
                        <ProtectedRoute
                          roles={superAdminPageRoles}
                          exact
                          path="/app/carrier-exclusions"
                          component={CarrierExclusions}
                        />
                        <ProtectedRoute
                          roles={superAdminPageRoles}
                          exact
                          path="/app/tcr-campaigns"
                          component={TCRCampaigns}
                        />
                        <ProtectedRoute roles={[Auth0Role.GR_STAFF_MANAGER]} exact path="/app/users" component={Users} />
                        <ProtectedRoute roles={[Auth0Role.GR_STAFF_MANAGER]} exact path="/app/users/new" component={UpsertUser} />
                        <ProtectedRoute roles={[Auth0Role.GR_STAFF_MANAGER]} exact path="/app/users/:id" component={ViewUser} />
                        <ProtectedRoute roles={[Auth0Role.GR_STAFF_MANAGER]} exact path="/app/users/:id/edit" component={UpsertUser} />
                        <Route component={PageNotFound} />
                      </Switch>
                    </div>
                  </main>
                </div>
              </div>
            </NotificationsProvider>
          </SidebarContextProvider>
        </SocketContextProvider>
      </MembershipContextProvider>
    </CacheContextProvider>
  );
};

export default AppWrapper;
