import { Auth0AuthorizerContext, Auth0Role } from "@gr/shared/models";

type Auth0RoleRank = number;
type Auth0RoleRankMap = Map<Auth0Role, Auth0RoleRank>;

const ROLE_RANK_MAP = getAuth0RoleRankMap();

export interface CheckAuth0RolesRequest {
  log?: boolean;
  userRoles: (Auth0Role | undefined)[] | undefined;
  targetRoles: (Auth0Role | undefined)[] | undefined;
}

export function checkAuth0Roles(request: CheckAuth0RolesRequest) {
  const isSuperAdmin = request?.userRoles?.filter(role => role)?.some(role => [Auth0Role.SUPER_ADMIN, Auth0Role.GR_ADMIN]?.includes(<Auth0Role>role)) ?? false;
  log(request, 'Is Super Admin?', isSuperAdmin);

  if (isSuperAdmin) {
    return true;
  }

  const userRolesCount = request?.userRoles?.length ?? 0;
  log(request, '# of User Roles', userRolesCount);

  const targetRolesCount = request?.targetRoles?.length ?? 0;
  log(request, '# of Target Roles', targetRolesCount);

  if (userRolesCount <= 0 || targetRolesCount <= 0) {
    return false;
  }

  const userRank = Math.max(...request?.userRoles?.filter(role => !!role)?.map(role => ROLE_RANK_MAP?.get(<Auth0Role>role) ?? -2) ?? [-2]);
  const targetRank = Math.min(...request?.targetRoles?.filter(role => !!role)?.map(role => ROLE_RANK_MAP?.get(<Auth0Role>role) ?? -1) ?? [-1]);

  log(request, 'User Rank', userRank);
  log(request, 'Target Rank', targetRank);

  return userRank >= targetRank;
}

export function checkContextRoles(context: Auth0AuthorizerContext, roles: Auth0Role[]) {
  return checkAuth0Roles({ userRoles: [context.role], targetRoles: roles });
}

export function getAuth0RoleRank(role: Auth0Role) {
  return ROLE_RANK_MAP?.get(role) ?? -1;
}

function getAuth0RoleRankMap(): Auth0RoleRankMap {
  // Higher Value = More Permissions
  // Roles with the same rank are equivalent

  const map = new Map();

  map.set(Auth0Role.STAFF, 0);
  map.set(Auth0Role.GR_CLICKER, 0);
  map.set(Auth0Role.GR_CLIENT_USER, 1);
  map.set(Auth0Role.GR_CLIENT_MANAGER, 2);
  map.set(Auth0Role.ADMIN, 3);
  map.set(Auth0Role.GR_STAFF_USER, 3);
  map.set(Auth0Role.GR_STAFF_MANAGER, 4);
  map.set(Auth0Role.SUPER_ADMIN, 5);
  map.set(Auth0Role.GR_ADMIN, 5);

  return map;
}

function log(request: CheckAuth0RolesRequest, ...value: any) {
  if (request?.log) {
    console.log(value);
  }
}
