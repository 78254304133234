import { CampaignTypeEnum, MessageTypeEnum } from '@gr/shared/enums';
import { ICalculateLeadsRequest, IContactListEntity, IHttpResponse } from '@gr/shared/models';
import { Transition } from '@headlessui/react';
import { Fragment, useState } from 'react';
import { axiosPost } from '../../authAxios';
import { Button } from '../shared/Buttons/Button';
import { Dropdown, IDropdownValue } from '../shared/Form/Dropdown';
import CampaignTypeForClientDropdown from '../shared/Form/Dropdowns/CampaignTypeDropdown';
import ClientDropdown from '../shared/Form/Dropdowns/ClientDropdown';
import LoadingIndicator from '../shared/LoadingIndicator';

interface IContactListLeadGenerationProps {
  selectedItem: IContactListEntity;
  totalContactCount: number;
}

const ContactListLeadGeneration = ({ selectedItem, totalContactCount }: IContactListLeadGenerationProps) => {
  const [leadsLoading, setLeadsLoading] = useState(false);
  const [showLeadsCount, setShowLeadsCount] = useState(false);
  const [leadsCount, setLeadsCount] = useState(0);

  const [selectedClient, setSelectedClient] = useState<IDropdownValue>();
  const [selectedCampaignType, setSelectedCampaignType] = useState<CampaignTypeEnum>();
  const [selectedMessageType, setSelectedMessageType] = useState<IDropdownValue>();
  const [collapseLeadGeneration, setCollapseLeadGeneration] = useState(false);

  const calculateLeads = async () => {
    setLeadsLoading(true);
    try {
      const request: ICalculateLeadsRequest = {
        contactListIds: [selectedItem!.id],
        clientId: selectedClient?.value,
        campaignType: selectedCampaignType!,
        campaignMessageType: selectedMessageType?.value!,
      };

      const {
        data: { data },
      } = await axiosPost<IHttpResponse<number>>('/contact-lists-calculate-leads', request);

      setLeadsCount(data);
    } catch (err) {
      console.error(err);
    } finally {
      setLeadsLoading(false);
      setShowLeadsCount(true);
    }
  };

  const handleClientSelected = (newValue: IDropdownValue | undefined) => {
    setSelectedClient(newValue);
    setSelectedCampaignType(undefined);
    setSelectedMessageType(undefined);
    setLeadsCount(0);
    setShowLeadsCount(false);
  };

  return (
    <div className="mt-4 mb-2 bg-white shadow dark:bg-slate-700 dark:border-2 dark:border-slate-800 sm:rounded-lg">
      <div
        className="px-4 py-3 sm:px-6"
        onClick={() => {
          if (totalContactCount > 0) {
            setCollapseLeadGeneration(!collapseLeadGeneration);
            setSelectedClient(undefined);
          }
        }}
      >
        <h3
          className={`text-lg font-medium leading-6   ${totalContactCount > 0 && 'text-sky-600 hover:underline hover:cursor-pointer'
            }`}
        >
          Lead Generation Calculator
        </h3>
        <p className="max-w-2xl mt-1 text-sm text-gray-500 dark:text-slate-400">
          Determine the number of leads for a given client and campaign type
        </p>
      </div>
      <Transition
        show={collapseLeadGeneration}
        as={Fragment}
        enter="ease-in-out duration-200"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="ease-in-out duration-200"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <div className="px-4 py-4 space-y-4 border-t border-gray-200 dark:border-slate-800">
          <ClientDropdown
            value={selectedClient}
            onChange={(newValue) => {
              handleClientSelected(newValue as IDropdownValue);
            }}
          />

          <Dropdown
            label="Message Type"
            value={selectedMessageType}
            onChange={(selectedValue: IDropdownValue) => {
              setSelectedMessageType(selectedValue);
            }}
            options={[
              { label: 'SMS', value: MessageTypeEnum.SMS },
              { label: 'MMS', value: MessageTypeEnum.MMS },
            ]}
          />

          <CampaignTypeForClientDropdown
            value={selectedCampaignType}
            clientId={selectedClient?.value ?? ''}
            messageType={selectedMessageType?.value}
            onChange={(newValue: IDropdownValue) => {
              setSelectedCampaignType(newValue.value);
            }}
          />

          <div className="flex">
            <Button
              onClick={calculateLeads}
              disabled={!selectedClient || !selectedCampaignType || !selectedMessageType || !selectedItem}
              text="Calculate Leads"
            />
            {leadsLoading && (
              <span className="inline-flex items-center ml-4">
                <LoadingIndicator />
              </span>
            )}
            {!leadsLoading && showLeadsCount && (
              <span className="inline-flex items-center ml-4">{leadsCount.toLocaleString()} leads</span>
            )}
          </div>
        </div>
      </Transition>
    </div>
  );
};

export default ContactListLeadGeneration;
